import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import {
  AUTH_TOKEN,
  AUTH_TOKEN_REFRESH,
  LAST_REQUESTED,
  USER_INFOS,
} from "redux/constants/Auth";
import { notification } from "antd";
import { getUserData } from "helpers/utils";
import FetcherService from "services/FetcherService";
import jwt from "jsonwebtoken";
import { signOut } from "redux/sagas/Auth";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
export const TOKEN_PAYLOAD_KEY = "authorization";

// API Request interceptor
service.interceptors.request.use(
  async (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);
    const lastconnected = Number(
      localStorage.getItem(LAST_REQUESTED) || Date.now()
    );

    const millis = Math.floor((Date.now() - lastconnected) / 86400000);

    if (millis > 1) {
      setTimeout(() => {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.clear();
        history.push("/auth/login");
        window.location.reload();
      }, 3000);

      const signOutUser = {};

      if (signOutUser.signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.clear();
        document.location.href = "/auth/login";
      }

      signOut();
    } else {
      if (jwtToken) {
        if (config.headers[TOKEN_PAYLOAD_KEY]) {
          let validToken = jwtToken;
          const now = Math.floor(Date.now() / 1000);
          if (getUserData()["exp"] < now) {
            await FetcherService.RenewToken(
              localStorage.getItem(AUTH_TOKEN_REFRESH)
            )
              .then((user) => {
                var decoded = jwt.decode(user.token);
                localStorage.setItem(USER_INFOS, JSON.stringify(decoded));
                localStorage.setItem(AUTH_TOKEN, user.token);
                localStorage.setItem(AUTH_TOKEN_REFRESH, user.refresh_token);
                validToken = user.token;
              })
              .catch((err) => {
                console.log(err);
              });
          }
          config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${validToken}`;
        } else {
          config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
        }
      } else {
        // console.log(jwtToken, config.headers);
        // history.push(ENTRY_ROUTE);
        // window.location.reload();
      }
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Erreur",
      description: "Une erreu a survecue",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    const now = Math.floor(Date.now());
    localStorage.setItem(LAST_REQUESTED, now);

    return response.data;
  },
  (error) => {
    console.log("ee > ", error.toJSON());
    let notificationParam = {
      message: "Une erreu a survecue.",
    };

    if (!error.response) {
      notificationParam.description = "Vérifiez votre connexion et reéssayez ";
    } else {
      // Remove token and redirect
      if (error.response.status === 400 || error.response.status === 403) {
        notificationParam.message = `${"Erreur de traitement"}`;
        notificationParam.description = "Réessayez s'il vous plaît !";
        if (error.response.data?.message) {
          notificationParam.description = error.response.data?.message;
        }
        if (`${error.response.data?.message}`.includes("Expired JWT Token")) {
          notificationParam.description = error.response.data?.message;
          localStorage.removeItem(AUTH_TOKEN);
          history.push(ENTRY_ROUTE);
          window.location.reload();
        }
      }

      if (error.response.status === 404) {
        notificationParam.message =
          "Une des informations fournit est introuvable";
      }

      if (error.response.status === 500) {
        notificationParam.message = "Une erreur interne a survecue";
      }

      if (error.response.status === 508) {
        notificationParam.message = "Le delai d'attente est expiré";
      }

      if (error.response.status === 422) {
        return Promise.reject(error.response.data);
      }
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
