import { API_BASE_URL as baseUrl } from "configs/AppConfig";
import FakeApi from "./FakeApi";
import axiosFetch, { TOKEN_PAYLOAD_KEY } from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "redux/constants/Auth";

function tokennize() {
  const jwtToken = localStorage.getItem(AUTH_TOKEN);
  return {
    [TOKEN_PAYLOAD_KEY]: `Bearer ${jwtToken}`,
  };
}

let FetcherService = {
  testerz: (setdada) => FakeApi.testerz(setdada),
  login: function (data) {
    return fetch({
      url: "/login_check",
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: JSON.stringify(data),
    });
  },
  // AuthSignin: (setdada) => FakeApi.AuthSignin(setdada),
  AuthSignin: async function (setdada) {
    let res = await fetch(baseUrl + "/login_check", {
      method: "post",
      body: JSON.stringify(setdada),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await res.json();
  },
  // GetUserData: (setdada) => FakeApi.GetUserData(setdada),
  GetUserData: async function (params) {
    return axiosFetch({
      url: `/users`,
      method: "get",
      params,
    });
  },
  GetEdsUserData: async function (params) {
    return axiosFetch({
      url: `/eds/users`,
      method: "get",
      params,
    });
  },
  // GetPublications: (setdada) => FakeApi.GetPublications(setdada),
  GetPublications: async function (params) {
    return axiosFetch({
      url: `/publications`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  // GetPublications: (setdada) => FakeApi.GetPublications(setdada),
  GetConversations: async function (params) {
    return axiosFetch({
      url: `/conversations`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetConversation: async function (id) {
    return axiosFetch({
      url: `/conversations/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetUser: async function (id) {
    return axiosFetch({
      url: `/users/${id}`,
      method: "get",
    });
  },
  GetRole: async function (id) {
    return axiosFetch({
      url: `/roles/${id}`,
      method: "get",
    });
  },
  // GetMessages: (setdada) => FakeApi.GetMessages(setdada),
  GetMessages: async function (params) {
    return axiosFetch({
      url: `/messages`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetEvents: async function (params) {
    return axiosFetch({
      url: `/events`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetEdsDepartement: async function (params) {
    return axiosFetch({
      url: `/eds/departements`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetEdsCommune: async function (params) {
    return axiosFetch({
      url: `/eds/communes`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetEdsArrondissement: async function (params) {
    return axiosFetch({
      url: `/eds/arrondissements`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetEdsQuartier: async function (params) {
    return axiosFetch({
      url: `/eds/quartiers`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetEventsStatistic: async function (id, params) {
    return axiosFetch({
      url: `/stat/event/${id}`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetAlerteStatistic: async function (id, begin, end) {
    return axiosFetch({
      url: `/stat/centre/alerte/${id}/${begin}/${end}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetGeneralEventsStatistic: async function (begin, end) {
    return axiosFetch({
      url: `/stat/events/${begin}/${end}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetGeneralAlertesStatistic: async function (begin, end) {
    return axiosFetch({
      url: `/stat/centres/alertes/${begin}/${end}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetNgoStatistic: async function (id) {
    return axiosFetch({
      url: `/stat/ong/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetNgoGeneralStatistic: async function (begin, end) {
    return axiosFetch({
      url: `/stat/ongs/${begin}/${end}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetMouchards: async function (begin, end) {
    return axiosFetch({
      url: `/mouchards`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetGeneralStatistic: async function (begin, end) {
    return axiosFetch({
      url: `/stat/general/${begin}/${end}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetEvent: async function (params, id) {
    return axiosFetch({
      url: `/events/${id}`,
      method: "get",
      params,
    });
  },

  GetRoleAcl: async function (params, id) {
    return axiosFetch({
      url: `/role_acls/${id}`,
      method: "get",
      params,
    });
  },
  GetRessourceAction: async function (params, id) {
    return axiosFetch({
      url: `/ressource_actions/${id}`,
      method: "get",
      params,
    });
  },
  GetRdvs: async function (params) {
    return axiosFetch({
      url: `/prise_rdvs`,
      method: "get",
      params,
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  GetDateRdvs: async function (params) {
    return axiosFetch({
      url: `/date_rdv_centres`,
      method: "get",
      params,
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  // GetParticipentEvent: (setdada) => FakeApi.GetParticipentEvent(setdada),
  GetParticipentEvent: async function (params) {
    return axiosFetch({
      url: `/participation_events`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      params,
    });
  },
  GetExams: async function (params) {
    return axiosFetch({
      url: `/examens`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      params,
    });
  },
  GetUsers: async function (params) {
    return axiosFetch({
      url: `/users`,
      method: "get",
      params,
    });
  },
  GetPrelevements: async function (params) {
    return axiosFetch({
      url: `/prelevements`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetResultatsExamen: async function (params) {
    return axiosFetch({
      url: `/resultat_examens`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetDonnors: async function (params) {
    return axiosFetch({
      url: `/fiche_inscriptions`,
      method: "get",
      params,
    });
  },
  GetOngs: async function (params) {
    return axiosFetch({
      url: `/users`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetAgents: async function (params) {
    return axiosFetch({
      url: `/agent_ants`,
      method: "get",
      params,
    });
  },
  // GetPublicationsCategory: (setdada) =>
  //   FakeApi.GetPublicationsCategory(setdada),
  GetPublicationsCategory: async function (params) {
    return axiosFetch({
      url: `/category_publications`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetThemesQuizz: async function (params) {
    return axiosFetch({
      url: `/theme_quizzes`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetFaqs: async function (params) {
    return axiosFetch({
      url: `/faqs`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetSlides: async function (params) {
    return axiosFetch({
      url: `/slides`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetLoocalities: async function (params) {
    return axiosFetch({
      url: `/eds/departements`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetRessources: async function (params) {
    return axiosFetch({
      url: `/ressources`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetActions: async function (params) {
    return axiosFetch({
      url: `/actions`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetGroupeRares: async function (params) {
    return axiosFetch({
      url: `/groupe_rares`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetCategoryFonction: async function (params) {
    return axiosFetch({
      url: `/category_fonctions`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetTypeExamen: async function (params) {
    return axiosFetch({
      url: `/type_examens`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetTypeResultat: async function (params) {
    return axiosFetch({
      url: `/type_resultats`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  // GetAlertes: (setdada) => FakeApi.GetParticipentEvent(setdada),
  GetNewsLetters: async function (params) {
    return axiosFetch({
      url: `/alertes`,
      method: "get",
      params,
    });
  },
  GetDepartements: async function (params) {
    return axiosFetch({
      url: `/eds/departements`,
      method: "get",
      params,
    });
  },
  GetRaisonAnnulation: async function (params) {
    return axiosFetch({
      url: `/raison_annulations`,
      method: "get",
      params,
    });
  },
  GetCenters: async function (params) {
    return axiosFetch({
      url: `/centres`,
      method: "get",
      params,
    });
  },
  GetCentreAgents: async function (params) {
    return axiosFetch({
      url: `/centre_agents`,
      method: "get",
      params,
    });
  },
  GetCenter: async function (id) {
    return axiosFetch({
      url: `/centres/${id}`,
      method: "get",
    });
  },
  GetSlide: async function (id) {
    return axiosFetch({
      url: `/slides/${id}`,
      method: "get",
    });
  },
  GetParametters: async function (id) {
    let url = baseUrl + `/parametres`;
    let res = await fetch(url, {
      method: "GET",
    });
    return await res.json();
  },
  RenewToken: async function (body) {
    let url = `${baseUrl}/token/renew?refresh_token=${body}`;
    let res = await fetch(url, {
      method: "POST",
      body: {},
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await res.json();
  },
  GetQuizzes: async function (params) {
    return axiosFetch({
      url: `/quizzes`,
      method: "get",
      params,
    });
  },
  GetThemes: async function (params) {
    return axiosFetch({
      url: `/theme_quizzes`,
      method: "get",
      params,
    });
  },
  GetPubCats: async function (params) {
    return axiosFetch({
      url: `/category_publications`,
      method: "get",
      params,
    });
  },
  GetFaqThemes: async function (params) {
    return axiosFetch({
      url: `/category_faqs`,
      method: "get",
      params,
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
    });
  },
  GetFonctions: async function (params) {
    return axiosFetch({
      url: `/fonctions`,
      method: "get",
      params,
    });
  },
  GetRoles: async function (params) {
    return axiosFetch({
      url: `/roles`,
      method: "get",
      params,
    });
  },
  // GetPublication: (setdada) => FakeApi.GetPublication(setdada),
  GetPublication: async function (params) {
    return axiosFetch({
      url: `publications/${params}`,
      method: "get",
    });
  },
  GetUserAnswers: (setdada) => FakeApi.GetUserAnswers(setdada),
  // GetUserAnswers: async function (params) {
  //   return axiosFetch({
  //     url: `publications/${params}`,
  //     method: "get",
  //   });
  // },
  PostPublication: function (data) {
    return axiosFetch({
      url: "/publications",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  RegenerateCentreRdv: function (id, data) {
    return axiosFetch({
      url: `/centres/${id}/generate/rdv`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostUser: function (data) {
    return axiosFetch({
      url: "/users",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostFicheUser: function (data) {
    return axiosFetch({
      url: "/fiche_inscriptions",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostParticipate: function (data) {
    return axiosFetch({
      url: `/participation_events`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostOng: function (data) {
    return axiosFetch({
      url: "/ongs",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostOrganisator: function (data) {
    return axiosFetch({
      url: "/agent_ants",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostQuizz: function (data) {
    return axiosFetch({
      url: "/quizzes",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
  },
  PostCentreAgent: function (data) {
    return axiosFetch({
      url: "/centre_agents",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostAcl: function (data) {
    return axiosFetch({
      url: "/user_acls",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostUserRole: function (data) {
    return axiosFetch({
      url: "/user_roles",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostRoleAcl: function (data) {
    return axiosFetch({
      url: "/role_acls",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostRessourceAction: function (data) {
    return axiosFetch({
      url: "/ressource_actions",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostResultsExam: function (data) {
    return axiosFetch({
      url: "/resultat_examens",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutResultsExam: function (id, data) {
    return axiosFetch({
      url: `/resultat_examens/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostDepartment: function (data) {
    return axiosFetch({
      url: "/departements",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostRaisonAnnulation: function (data) {
    return axiosFetch({
      url: "/raison_annulations",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostFaq: function (data) {
    return axiosFetch({
      url: "/faqs",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostThemeQuizz: function (data) {
    return axiosFetch({
      url: "/theme_quizzes",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostThemeFaq: function (data) {
    return axiosFetch({
      url: "/category_faqs",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostGroupeRare: function (data) {
    return axiosFetch({
      url: "/groupe_rares",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostAlerte: function (data) {
    return axiosFetch({
      url: "/alertes",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PosCatFonction: function (data) {
    return axiosFetch({
      url: "/category_fonctions",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PosCatResultat: function (data) {
    return axiosFetch({
      url: "/type_resultats",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostTypeExamen: function (data) {
    return axiosFetch({
      url: "/type_examens",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostFonction: function (data) {
    return axiosFetch({
      url: "/fonctions",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostRole: function (data) {
    return axiosFetch({
      url: "/roles",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostExamen: function (data) {
    return axiosFetch({
      url: "/examens",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostEvent: function (data) {
    return axiosFetch({
      url: "/events",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostPubCat: function (data) {
    return axiosFetch({
      url: "/category_publications",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
  },
  PostLocality: function (data) {
    return axiosFetch({
      url: "/localites",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
  },
  PostCenter: function (data) {
    return axiosFetch({
      url: "/centres",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostSlide: function (data) {
    return axiosFetch({
      url: "/slides",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostPrelevement: function (data) {
    return axiosFetch({
      url: "/prelevements",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostMessage: function (data) {
    return axiosFetch({
      url: "/messages",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostParameter: function (data) {
    return axiosFetch({
      url: "/parametres",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PostSuggestion: function (data) {
    return axiosFetch({
      url: "/quiz_suggestions",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
  },
  PutPublication: function (id, data) {
    return axiosFetch({
      url: `publications/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutEvent: function (id, data) {
    return axiosFetch({
      url: `events/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutRdv: function (id, data) {
    return axiosFetch({
      url: `prise_rdvs/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutDateRdv: function (id, data) {
    return axiosFetch({
      url: `/date_rdv_centres/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutParameter: function (id, data) {
    return axiosFetch({
      url: `/parametres/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutFaq: function (id, data) {
    return axiosFetch({
      url: `faqs/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutCenter: function (id, data) {
    return axiosFetch({
      url: `/centres/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutSlide: function (id, data) {
    return axiosFetch({
      url: `/slides/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutAgent: function (id, data) {
    return axiosFetch({
      url: `/agent_ants/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  ChangePass: function (id, data) {
    return axiosFetch({
      url: `/u/reset-new-password`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  ForgotPass: function (id, data) {
    return axiosFetch({
      url: `/u/reset-lost-password`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
  },
  PutPrelevement: function (id, data) {
    return axiosFetch({
      url: `/prelevements/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutSuggesetion: function (id, data) {
    return axiosFetch({
      url: `/quiz_suggestions/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
      data: JSON.stringify(data),
    });
  },
  PutUser: function (id, data) {
    return axiosFetch({
      url: `users/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutQuizz: function (id, data) {
    return axiosFetch({
      url: `/quizzes/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutLocalites: function (id, data) {
    return axiosFetch({
      url: `/localites/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutParticipate: function (id, data) {
    return axiosFetch({
      url: `/participation_events/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutDeps: function (id, data) {
    return axiosFetch({
      url: `/departements/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
      data: JSON.stringify(data),
    });
  },
  putRaisonAnnulation: function (id, data) {
    return axiosFetch({
      url: `/raison_annulations/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
      data: JSON.stringify(data),
    });
  },
  PutCenters: function (id, data) {
    return axiosFetch({
      url: `/centres/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutMessage: function (id, data) {
    return axiosFetch({
      url: `/messages/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutThemeQuizz: function (id, data) {
    return axiosFetch({
      url: `/theme_quizzes/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
      data: JSON.stringify(data),
    });
  },
  PutPubCat: function (id, data) {
    return axiosFetch({
      url: `/category_publications/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
      data: JSON.stringify(data),
    });
  },
  PutThemeFaq: function (id, data) {
    return axiosFetch({
      url: `/category_faqs/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutGrare: function (id, data) {
    return axiosFetch({
      url: `/groupe_rares/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutCatFonction: function (id, data) {
    return axiosFetch({
      url: `/category_fonctions/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutTypeExamen: function (id, data) {
    return axiosFetch({
      url: `/type_examens/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutTypeResultat: function (id, data) {
    return axiosFetch({
      url: `/type_resultats/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutFonction: function (id, data) {
    return axiosFetch({
      url: `/fonctions/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutRole: function (id, data) {
    return axiosFetch({
      url: `/roles/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  PutExam: function (id, data) {
    return axiosFetch({
      url: `/examens/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        ...tokennize(),
      },
      data: JSON.stringify(data),
    });
  },
  deletePublication: function (id, data) {
    console.log(id);
    return axiosFetch({
      url: `publications/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteCentreAgent: function (id, data) {
    return axiosFetch({
      url: `centre_agents/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteAcl: function (id, data) {
    return axiosFetch({
      url: `user_acls/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteRoleAcl: function (id, data) {
    return axiosFetch({
      url: `role_acls/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteRessourceAction: function (id, data) {
    return axiosFetch({
      url: `ressource_actions/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteAgentAnts: function (id, data) {
    return axiosFetch({
      url: `agent_ants/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteAdmin: function (id, data) {
    return axiosFetch({
      url: `/users/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteDonnors: function (id, data) {
    return axiosFetch({
      url: `/fiche_inscriptions/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteQuizz: function (id, data) {
    return axiosFetch({
      url: `/quizzes/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteParticipation: function (id, data) {
    return axiosFetch({
      url: `/participation_events/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deletePrelevement: function (id, data) {
    return axiosFetch({
      url: `/prelevements/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteFaq: function (id, data) {
    return axiosFetch({
      url: `/faqs/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteLocalite: function (id, data) {
    return axiosFetch({
      url: `/localites/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteDepartement: function (id, data) {
    return axiosFetch({
      url: `/departements/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteRaisonAnnulation: function (id, data) {
    return axiosFetch({
      url: `/raison_annulations/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteCenters: function (id, data) {
    return axiosFetch({
      url: `/centres/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteSlider: function (id, data) {
    return axiosFetch({
      url: `/slides/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteSuggestionQuizz: function (id, data) {
    return axiosFetch({
      url: `/quiz_suggestions/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteThemeQuizz: function (id, data) {
    return axiosFetch({
      url: `/theme_quizzes/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteEvents: function (id, data) {
    return axiosFetch({
      url: `/events/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteRdvs: function (id, data) {
    return axiosFetch({
      url: `/prise_rdvs/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteDate: function (id, data) {
    return axiosFetch({
      url: `/date_rdv_centres/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteResultatsExamen: function (id, data) {
    return axiosFetch({
      url: `/resultat_examens/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteExamen: function (id, data) {
    return axiosFetch({
      url: `/examens/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deletePubCat: function (id, data) {
    return axiosFetch({
      url: `/category_publications/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteThemeFaq: function (id, data) {
    return axiosFetch({
      url: `/category_faqs/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteGroupeRares: function (id, data) {
    return axiosFetch({
      url: `/groupe_rares/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteAlertes: function (id, data) {
    return axiosFetch({
      url: `/alertes/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteCatFonction: function (id, data) {
    return axiosFetch({
      url: `/category_fonctions/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deletTypeExamen: function (id, data) {
    return axiosFetch({
      url: `/type_examens/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deletTypeResultat: function (id, data) {
    return axiosFetch({
      url: `/type_resultats/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteFonctions: function (id, data) {
    return axiosFetch({
      url: `/fonctions/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteRole: function (id, data) {
    return axiosFetch({
      url: `/roles/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteOng: function (id, data) {
    return axiosFetch({
      url: `/ongs/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  deleteUser: function (id, data) {
    return axiosFetch({
      url: `users/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "aapplication/json",
        ...tokennize(),
      },
    });
  },
  PostMedia: function (data) {
    return axiosFetch({
      url: "/media_objects",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
  },
  GetFormatorUnity: async function (setdada, body) {
    let url = baseUrl + "/auth/users/unity/getformator/" + setdada;
    let res = await fetch(url, {
      method: "POST",
      credentials: "include",
      body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return await res.json();
  },
  ActivateUser: async function (setdada, option) {
    let url = baseUrl + "/auth/users/active/option/" + option;
    let res = await fetch(url, {
      method: "post",
      credentials: "include",
      body: setdada,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return await res.json();
  },
};

export default FetcherService;
