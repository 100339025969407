import React from "react";
import { APP_NAME } from "configs/AppConfig";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { getMediaUrl } from "helpers/utils";

export default function Footer() {
  const {  newConfig } = useSelector((state) => state.theme);
  let { appDescription, appName, appLogoSite } = newConfig;
  return (
    <footer className="footer mt-3">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <Tooltip title={appDescription}>
          <span className="font-weight-semibold">
            {`${appName || APP_NAME}`}
          </span>
        </Tooltip>{" "}
        Tout droit reservé.
      </span>
      <img
        className="img-fluid mt-5"
        src={getMediaUrl(appLogoSite)}
        alt=""
        width={50}
        height={50}
      />
    </footer>
  );
}
