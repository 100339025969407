import {
  DotChartOutlined,
  LayoutOutlined,
  UserSwitchOutlined,
  UserOutlined,
  EnvironmentOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  DeleteRowOutlined,
  WechatOutlined,
  SettingOutlined,
  BookOutlined,
  IdcardOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  StockOutlined,
  PieChartOutlined,
  ExceptionOutlined,
  PartitionOutlined,
  SubnodeOutlined,
  SelectOutlined,
  HomeOutlined,
  SwitcherOutlined,
  DeploymentUnitOutlined,
  MailOutlined,
  SisternodeOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { hasPermission, hasRole } from "helpers/utils";
import {
  MdCorporateFare,
  MdOutlineAdminPanelSettings,
  MdWorkOutline,
} from "react-icons/md";
import { BsFillPinMapFill } from "react-icons/bs";
import { FaMapSigns, FaQuestionCircle, FaQuoteLeft } from "react-icons/fa";
import { IoExtensionPuzzle, IoLayersOutline } from "react-icons/io5";
import { FcDataEncryption, FcPlanner, FcStatistics } from "react-icons/fc";
import { BsPersonLinesFill } from "react-icons/bs";
import { FiUserCheck, FiUserPlus } from "react-icons/fi";
const navigationConfig = [];
const HomeNavTree = [
  {
    key: "home-news",
    path: `${APP_PREFIX_PATH}/dashboards/home`,
    title: "generic.hidden",
    icon: HomeOutlined,
    breadcrumb: false,
    permission: true,
    submenu: [
      {
        key: "home-welcome",
        path: `${APP_PREFIX_PATH}/dashboards/home`,
        title: "sidenav.home.home",
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
        permission: true,
      },
    ],
  },
];

const SettingsNav = [
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: "sidenav.settings",
    icon: SettingOutlined,
    breadcrumb: false,
    permission:
      hasRole(["ROLE_ADMIN"]) ||
      hasPermission([
        ["READ", "Parametre"],
        ["READ", "Slide"],
        ["READ", "User"],
        ["READ", "FicheInscription"],
        ["READ", "Ong"],
        ["READ", "Roles"],
        ["READ", "RessourceActions"],
      ]),
    submenu: [
      {
        key: "settings-chat",
        path: `${APP_PREFIX_PATH}/settings/parameters`,
        title: "sidenav.settings.parameters",
        icon: IdcardOutlined,
        breadcrumb: false,
        submenu: [],
        permission:
          hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "Parametre"]]),
      },
      {
        key: "settings-slide",
        path: `${APP_PREFIX_PATH}/settings/slides`,
        title: "sidenav.settings.slides",
        icon: SwitcherOutlined,
        breadcrumb: false,
        submenu: [],
        permission:
          hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "Slide"]]),
      },
      {
        key: "usersNav",
        path: `${APP_PREFIX_PATH}/users`,
        title: "sidenav.users",
        breadcrumb: false,
        permission:
          hasRole([
            "ROLE_ADMIN",
            "ROLE_AGENT_ANTS",
            "ROLE_GEST_USER",
            "ROLE_GEST_AGENT",
          ]) ||
          hasPermission([
            ["READ", "User"],
            ["READ", "FicheInscription"],
            ["READ", "Ong"],
          ]),
        submenu: [
          {
            key: "users-all",
            path: `${APP_PREFIX_PATH}/users/all`,
            title: "sidenav.users.all",
            icon: UserOutlined,
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_GEST_USER"]) ||
              hasPermission([["READ", "User"]]),
            submenu: [],
          },
          {
            key: "users-ficheDonors",
            path: `${APP_PREFIX_PATH}/users/fiches`,
            title: "sidenav.users.ficheDonors",
            icon: <FiUserPlus className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_GEST_USER"]) ||
              hasPermission([["READ", "FicheInscription"]]),
            submenu: [],
          },
          {
            key: "users-donors",
            path: `${APP_PREFIX_PATH}/users/donors`,
            title: "sidenav.users.donors",
            icon: <FiUserCheck className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_GEST_USER"]) ||
              hasPermission([["READ", "User"]]),
            submenu: [],
          },
          {
            key: "users-ongs",
            path: `${APP_PREFIX_PATH}/users/ongs`,
            title: "sidenav.users.ongs",
            icon: <MdCorporateFare className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_GEST_USER"]) ||
              hasPermission([["READ", "Ong"]]),
            submenu: [],
          },
          {
            key: "users-organisators",
            path: `${APP_PREFIX_PATH}/users/organisators`,
            title: "sidenav.users.organisators",
            icon: UserSwitchOutlined,
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_GEST_AGENT"]) ||
              hasPermission([["READ", "User"]]),
            submenu: [],
          },
          {
            key: "users-admins",
            path: `${APP_PREFIX_PATH}/users/admins`,
            title: "sidenav.users.admins",
            icon: (
              <MdOutlineAdminPanelSettings className="menu-ri-icon" size={18} />
            ),
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "User"]]),
            submenu: [],
          },
        ],
      },
      {
        key: "gest-role-nav",
        path: `${APP_PREFIX_PATH}/extra`,
        title: "sidenav.gestrole",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN"]) ||
          hasPermission([
            ["READ", "Roles"],
            ["READ", "RessourceActions"],
          ]),
        submenu: [
          {
            key: "extra-roles",
            path: `${APP_PREFIX_PATH}/extras/roles`,
            title: "sidenav.extra.Roles",
            icon: PartitionOutlined,
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "Roles"]]),
            submenu: [],
          },
          {
            key: "extra-actions",
            path: `${APP_PREFIX_PATH}/extras/roles-actions`,
            title: "sidenav.extra.RolesActions",
            icon: SelectOutlined,
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN"]) ||
              hasPermission([["READ", "RessourceActions"]]),
            submenu: [],
          },
        ],
      },
    ],
  },
];
const ToolNav = [
  {
    key: "tools-nav",
    path: `${APP_PREFIX_PATH}/extra`,
    title: "sidenav.tools",
    breadcrumb: false,
    permission:
      hasRole(["ROLE_ADMIN", "ROLE_ENCODEUR", "ROLE_TECHNO_STRUCTURE"]) ||
      hasPermission([
        ["READ", "Fonction"],
        ["READ", "CategoryFonction"],
        ["READ", "GroupeRare"],
        ["READ", "TypeExamen"],
        ["READ", "TypeResultat"],
        ["READ", "Departement"],
        ["READ", "Localite"],
        ["READ", "Centre"],
      ]),
    submenu: [
     /* {
        key: "departement-organisators",
        path: `${APP_PREFIX_PATH}/emplacements/departements`,
        title: "sidenav.emplacements.departements",
        icon: EnvironmentOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_TERRIEN"]) ||
          hasPermission([["READ", "Departement"]]),
        submenu: [],
      },
      {
        key: "emplacements-localite",
        path: `${APP_PREFIX_PATH}/emplacements/localities`,
        title: "sidenav.emplacements.localite",
        icon: <FaMapSigns className="menu-ri-icon" size={18} />,
        icontype: "ri",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "Localite"]]),
        submenu: [],
      },*/
      {
        key: "emplacements-centres",
        path: `${APP_PREFIX_PATH}/emplacements/centers`,
        title: "sidenav.emplacements.centers",
        icon: <BsFillPinMapFill className="menu-ri-icon" size={18} />,
        icontype: "ri",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_TERRIEN"]) ||
          hasPermission([["READ", "Centre"]]),
        submenu: [],
      },
     /* {
        key: "extra-grouperare",
        path: `${APP_PREFIX_PATH}/extras/groupe-sanguins`,
        title: "sidenav.dashboard.raregroup",
        icon: DeleteRowOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_TECHNO_STRUCTURE"]) ||
          hasPermission([["READ", "GroupeRare"]]),
        submenu: [],
      }, */
      {
        key: "tool-profession",
        path: `${APP_PREFIX_PATH}/extras/category-fonctions`,
        title: "sidenav.extra.fonctions",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_TECHNO_STRUCTURE"]) ||
          hasPermission([
            ["READ", "CategoryFonction"],
            ["READ", "Fonction"],
          ]),
        submenu: [
          {
            key: "extra-cat-fonction",
            path: `${APP_PREFIX_PATH}/extras/category-fonctions`,
            title: "sidenav.extra.catfonctions",
            icon: <MdWorkOutline className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_TECHNO_STRUCTURE"]) ||
              hasPermission([["READ", "CategoryFonction"]]),
            submenu: [],
          },
          {
            key: "extra-fonction",
            path: `${APP_PREFIX_PATH}/extras/fonctions`,
            title: "sidenav.extra.fonctions",
            icon: <MdWorkOutline className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_TECHNO_STRUCTURE"]) ||
              hasPermission([["READ", "Fonction"]]),
            submenu: [],
          },
        ],
      },
    ],
  },
];
const EncodageNav = [
  {
    key: "encodage",
    path: `${APP_PREFIX_PATH}/encodage`,
    title: "sidenav.encodage",
    breadcrumb: false,
    permission:
      hasRole(["ROLE_ADMIN", "ROLE_ENCODEUR"]) ||
      hasPermission([
        ["READ", "Prelevements"],
        ["READ", "Examens"],
        ["READ", "ResultatExamens"],
        ["READ", "RaisonAnnulation"],
        ["READ", "TypeExamen"],
        ["READ", "Examen"],
        ["READ", "TypeResultat"],
      ]),
    submenu: [
      {
        key: "extra-raison",
        path: `${APP_PREFIX_PATH}/extras/reasons`,
        title: "sidenav.extra.reasons",
        icon: ExceptionOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN"]) ||
          hasPermission([["READ", "RaisonAnnulation"]]),
        submenu: [],
      },
      {
        key: "extra-tool-examen",
        path: `${APP_PREFIX_PATH}/extras/type-examen`,
        title: "sidenav.extra.examen",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_ENCODEUR", "ROLE_TECHNO_STRUCTURE"]) ||
          hasPermission([
            ["READ", "TypeExamen"],
            ["READ", "Examen"],
          ]),
        submenu: [
          {
            key: "extra-cat-examen",
            path: `${APP_PREFIX_PATH}/extras/type-examen`,
            icon: DeleteRowOutlined,
            breadcrumb: false,
            title: "sidenav.extra.catexamen",
            submenu: [],
            permission:
              hasRole([
                "ROLE_ADMIN",
                "ROLE_ENCODEUR",
                "ROLE_TECHNO_STRUCTURE",
              ]) ||
              hasPermission([
                ["READ", "TypeExamen"],
                ["READ", "Examens"],
              ]),
          },
          {
            key: "extra-examens",
            path: `${APP_PREFIX_PATH}/extras/examen`,
            title: "sidenav.extra.examen",
            icon: <FcDataEncryption className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole([
                "ROLE_ADMIN",
                "ROLE_ENCODEUR",
                "ROLE_TECHNO_STRUCTURE",
              ]) ||
              hasPermission([
                ["READ", "Examen"],
                ["READ", "Examens"],
              ]),
            submenu: [],
          },
        ],
      },

      {
        key: "extra-tperesult",
        path: `${APP_PREFIX_PATH}/extras/type-resultat`,
        title: "sidenav.extra.typeresultat",
        icon: SubnodeOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_ENCODEUR"]) ||
          hasPermission([["READ", "TypeResultat"]]),
        submenu: [],
      },
      {
        key: "encodage-prelevement",
        path: `${APP_PREFIX_PATH}/encodage/prelevement`,
        icon: <FcDataEncryption className="menu-ri-icon" size={18} />,
        title: "sidenav.encodage.prelevement",
        icontype: "ri",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ENCODEUR"]) ||
          hasPermission([
            ["READ", "Prelevements"],
            ["READ", "TypeExamen"],
            ["READ", "Examen"],
            ["READ", "Examens"],
            ["READ", "TypeResultat"],
          ]),
        submenu: [],
      },
      {
        key: "encodage-prelevement-rdv",
        path: `${APP_PREFIX_PATH}/encodage/prelevement-rdv`,
        icon: <FcDataEncryption className="menu-ri-icon" size={18} />,
        title: "sidenav.encodage.prelevement.rdv",
        icontype: "ri",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ENCODEUR"]) ||
          hasPermission([
            ["READ", "Prelevements"],
            ["READ", "TypeExamen"],
            ["READ", "Examen"],
            ["READ", "Examens"],
            ["READ", "TypeResultat"],
          ]),
        submenu: [],
      },
    ],
  },
];

const dashBoardNavTree = [
  {
    key: "dashboards-news",
    path: `${APP_PREFIX_PATH}/dashboards/news`,
    title: "sidenav.dashboard.news",
    icon: DotChartOutlined,
    breadcrumb: false,
    permission:
      hasRole(["ROLE_ADMIN"]) ||
      hasPermission([
        ["READ", "CategoryPublication"],
        ["READ", "Publication"],
        ["READ", "Alerte"],
        ["READ", "Message"],
      ]),
    submenu: [
      {
        key: "dashboards-publication",
        path: `${APP_PREFIX_PATH}/dashboards/news/useful-category`,
        title: "sidenav.news.publication",
        icon: AppstoreOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN"]) ||
          hasPermission([
            ["READ", "CategoryPublication"],
            ["READ", "Publication"],
          ]),
        submenu: [
          {
            key: "dashboards-usefulCategory",
            path: `${APP_PREFIX_PATH}/dashboards/news/useful-category`,
            title: "sidenav.news.usefulCategory",
            icon: AppstoreOutlined,
            breadcrumb: false,
            submenu: [],
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_JOURNALISTE"]) ||
              hasPermission([["READ", "CategoryPublication"]]),
          },
          {
            key: "news",
            path: `${APP_PREFIX_PATH}/dashboards/news/publications`,
            title: "sidenav.news",
            icon: LayoutOutlined,
            breadcrumb: false,
            submenu: [],
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_JOURNALISTE"]) ||
              hasPermission([["READ", "Publication"]]),
          },
        ],
      },
      {
        key: "conversations-chat",
        path: `${APP_PREFIX_PATH}/conversations/chats`,
        title: "sidenav.conversations.discussion",
        icon: WechatOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "Message"]]),
        submenu: [],
      },
      {
        key: "extra-newsletter",
        path: `${APP_PREFIX_PATH}/extras/newsletter`,
        title: "sidenav.dashboard.newsletter",
        icon: ProfileOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_TECHNO_STRUCTURE"]) ||
          hasPermission([["READ", "Alerte"]]),
        submenu: [],
      },
    ],
  },
];
const EventNav = [
  {
    key: "events",
    path: `${APP_PREFIX_PATH}/campaign`,
    title: "sidenav.campaign",
    breadcrumb: false,
    permission:
      hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_JOURNALISTE"]) ||
      hasPermission([
        ["READ", "Event"],
        ["READ", "ParticipationEvent"],
        ["READ", "PriseRdv"],
      ]),
    submenu: [
      {
        key: "dashboards-events",
        path: `${APP_PREFIX_PATH}/dashboards/events`,
        title: "sidenav.dashboard.events",
        icon: CalendarOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_GESTIONNAIRE"]) ||
          hasPermission([["READ", "Event"]]),
        submenu: [],
      },
      {
        key: "dashboards-participacion",
        path: `${APP_PREFIX_PATH}/dashboards/participations`,
        title: "sidenav.event.participation",
        icon: DeploymentUnitOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_GESTIONNAIRE"]) ||
          hasPermission([["READ", "ParticipationEvent"]]),
        submenu: [
          {
            key: "participacion-ficheDonors",
            path: `${APP_PREFIX_PATH}/dashboards/participations/rdv`,
            title: "sidenav.participacion.rdv",
            icon: <BsPersonLinesFill className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_GESTIONNAIRE"]) ||
              hasPermission([["READ", "ParticipationEvent"]]),
            submenu: [],
          },
          {
            key: "participacion-donors",
            path: `${APP_PREFIX_PATH}/dashboards/participations`,
            title: "sidenav.participacion.event",
            icon: <BsPersonLinesFill className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_GESTIONNAIRE"]) ||
              hasPermission([["READ", "ParticipationEvent"]]),
            submenu: [],
          },
        ],
      },
      {
        key: "dashboards-priseRdv",
        path: `${APP_PREFIX_PATH}/dashboards/rdv`,
        title: "sidenav.event.rdvs",
        icon: <FcPlanner className="menu-ri-icon" size={18} />,
        icontype: "ri",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_GESTIONNAIRE"]) ||
          hasPermission([["READ", "PriseRdv"]]),
        submenu: [],
      },
    ],
  },
];
const quizzesNAv = [
  {
    key: "quizzes",
    path: `${APP_PREFIX_PATH}/questions`,
    title: "sidenav.questions",
    breadcrumb: false,
    permission:
      hasRole(["ROLE_ADMIN"]) ||
      hasPermission([
        ["READ", "ThemeQuiz"],
        ["READ", "Quiz"],
        ["READ", "CategoryFaq"],
        ["READ", "Faq"],
      ]),
    submenu: [
      {
        key: "quizzes-quizz",
        path: `${APP_PREFIX_PATH}/questions/quizzes/themes?themes=quizz`,
        title: "sidenav.questions.quizzes",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN"]) ||
          hasPermission([
            ["READ", "ThemeQuiz"],
            ["READ", "Quiz"],
          ]),
        submenu: [
          {
            key: "quizzes-organisators",
            path: `${APP_PREFIX_PATH}/questions/quizzes/themes?themes=quizz`,
            title: "sidenav.questions.category",
            icon: <IoLayersOutline className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "ThemeQuiz"]]),
            submenu: [],
          },
          {
            key: "quizzes-mail",
            path: `${APP_PREFIX_PATH}/questions/quizzes`,
            title: "sidenav.questions.quizzes",
            icon: <IoExtensionPuzzle className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "Quiz"]]),
            submenu: [],
          },
        ],
      },

      {
        key: "faq-quizz",
        path: `${APP_PREFIX_PATH}/faqs/categories`,
        title: "sidenav.faq.faq",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN"]) ||
          hasPermission([
            ["READ", "CategoryFaq"],
            ["READ", "Faq"],
          ]),
        submenu: [
          {
            key: "faq-organisators",
            path: `${APP_PREFIX_PATH}/faqs/categories`,
            title: "sidenav.faq.category",
            icon: <FaQuoteLeft className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN"]) ||
              hasPermission([["READ", "CategoryFaq"]]),
            submenu: [],
          },
          {
            key: "faq-mail",
            path: `${APP_PREFIX_PATH}/faqs/questions`,
            title: "sidenav.faq.faq",
            icon: <FaQuestionCircle className="menu-ri-icon" size={18} />,
            icontype: "ri",
            breadcrumb: false,
            permission:
              hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "Faq"]]),
            submenu: [],
          },
        ],
      },
    ],
  },
];

const StatisticNav = [
  {
    key: "statistics",
    path: `${APP_PREFIX_PATH}/statistics`,
    title: "sidenav.statistics",
    breadcrumb: true,
    permission:
      hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_MONITEUR"]) ||
      hasPermission([
        ["READ", "Event"],
        ["READ", "Ong"],
      ]),
    submenu: [
      {
        key: "statistics-general",
        path: `${APP_PREFIX_PATH}/statistics/general`,
        title: "sidenav.statistics.general",
        icon: <FcStatistics className="menu-ri-icon" size={18} />,
        icontype: "ri",
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_MONITEUR"]) ||
          hasPermission([["READ", "Event"]]),
        submenu: [],
      },
      {
        key: "statistics-events",
        path: `${APP_PREFIX_PATH}/statistics/events`,
        title: "sidenav.statistics.events",
        icon: StockOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_MONITEUR"]) ||
          hasPermission([["READ", "Event"]]),
        submenu: [],
      },
      {
        key: "statistics-priseRdv",
        path: `${APP_PREFIX_PATH}/statistics/ngo`,
        title: "sidenav.statistics.ngo",
        icon: PieChartOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_MONITEUR"]) ||
          hasPermission([["READ", "Ong"]]),
        submenu: [],
      },
      {
        key: "statistics-message",
        path: `${APP_PREFIX_PATH}/statistics/alertes`,
        title: "sidenav.statistics.alertes",
        icon: MailOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_MONITEUR"]) ||
          hasPermission([["READ", "Message"]]),
        submenu: [],
      },
      {
        key: "statistics-mouchard",
        path: `${APP_PREFIX_PATH}/statistics/activity-log`,
        title: "sidenav.statistics.mouchard",
        icon: SisternodeOutlined,
        breadcrumb: false,
        permission:
          hasRole(["ROLE_ADMIN"]) || hasPermission([["READ", "Mouchard"]]),
        submenu: [],
      },
    ],
  },
];

const docsNavTree = [
  {
    key: "docs",
    path: `${APP_PREFIX_PATH}/docs`,
    title: "sidenav.docs",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "docs-documentation",
        path: `${APP_PREFIX_PATH}/docs/documentation`,
        title: "sidenav.docs.documentation",
        icon: FileUnknownOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];
navigationConfig.push(...HomeNavTree);
navigationConfig.push(...SettingsNav);
navigationConfig.push(...ToolNav);
// navigationConfig.push(...EncodageNav);

navigationConfig.push(...dashBoardNavTree);
navigationConfig.push(...EventNav);
navigationConfig.push(...quizzesNAv);

navigationConfig.push(...StatisticNav);
navigationConfig.push(...docsNavTree);
// navigationConfig.push([...appsNavTree, ...extraNavTree]);
export default navigationConfig;
