export const SIGNIN = "SIGNIN";
export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const AUTH_TOKEN = "auth_token";
export const AUTH_TOKEN_REFRESH = "raby_raby";
export const LAST_REQUESTED = "LAST_REQUESTED";
export const USER_INFOS = "user_INFOS";
export const USER_DATA = "user_DATA";
export const ADMIN_SECRET = "___lo_retry_adi";
export const AGENT_SECRET = "___lo_retry";
export const INVITE_SECRET = "___lo_retry98";
export const SIGNIN_WITH_GOOGLE = "SIGNIN_WITH_GOOGLE";
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED =
  "SIGNIN_WITH_GOOGLE_AUTHENTICATED";
export const SIGNIN_WITH_FACEBOOK = "SIGNIN_WITH_FACEBOOK";
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED =
  "SIGNIN_WITH_FACEBOOK_AUTHENTICATED";
