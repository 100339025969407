const host = document.location.href;

const dev = {
  API_ENDPOINT_URL:
    host.includes("localhost")
    ? "https://api.dondesang.test/api"
      : "https://api.dondesang.bj/api",
};

const prod = {
  API_ENDPOINT_URL:
    host.includes("localhost")
    ? "https://api.dondesang.test/api"
      : "https://api.dondesang.bj/api",
};

const test = {
  API_ENDPOINT_URL: "https://api.test.com",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};
export const env = getEnv();
