import { API_BASE_URL } from "configs/AppConfig";
import { eventType, extraroles, userroles } from "constants/properties.js";
import { USER_DATA, USER_INFOS } from "redux/constants/Auth";

/**
 *to convert ms to date
 * @param {conv} time the ms time
 */
export function secondTotime(time) {
  return ~~(time / 60) + ":" + (time % 60 < 10 ? "0" : "") + (time % 60);
}

/**
 *to convert object to hour
 * @param {conv} time the ms time
 */
export function computeTime(time) {
  let car = {
    1: 24,
    2: 1,
    3: 0.017,
  };
  //"duration": "{\"dur\":66,\"unit\":1}"
  return time.dur * car[time.unit];
}

/**
 *to convert object to hour
 * @param {conv} time the ms time
 */
export function hour_to_string(time, separator = " h ", limit = " min") {
  let plain = Math.trunc(time);
  let minute = Math.trunc(60 * (time - plain));
  return `${
    plain.toString().length === 1 ? "0" + plain : plain
  } ${separator.toString()} ${
    minute.toString().length === 1 ? "0" + minute : minute
  } ${limit.toString()} `;
}

/**
 *to random a value from an object
 * @param {conv} time the ms time
 */
export function date_stringer(last_modified) {
  let year = last_modified.getFullYear(),
    month = last_modified.getMonth() + 1,
    dt = last_modified.getDate();
  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return year + "-" + month + "-" + dt;
}

/**
 *to convert data to strin date format "yyyy/mm/dd"
 * @param {conv} time the ms time
 */
export function date_to_string(last_modified, whithHour) {
  if (last_modified) {
    last_modified = new Date(last_modified);
    let year = last_modified.getFullYear();
    let month = last_modified.getMonth() + 1;
    let dt = last_modified.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    let strdate = year + "-" + month + "-" + dt;
    strdate += whithHour
      ? " , " + last_modified.getHours() + ":" + last_modified.getMinutes()
      : "";

    return strdate;
  }
}

/**
 *ordon list based on @param {compar} a property
 */
export function ordoner(res, compar, order = 1) {
  return res.sort(function compare(a, b) {
    let fi = compar ? a[compar] : a;
    let se = compar ? b[compar] : b;
    if (fi < se) {
      return -order || -1;
    }
    if (fi > se) {
      return order || 1;
    }
    return 0;
  });
}

/**
 *join les noms par des tirets @param {compar} a property
 */
export function hypheny(res) {
  return res ? res.replace(/\s+/g, "-") : "";
}
/**
 *serealiser les donnéess @param {compar} a property
 */
export function UriEncoder(obj) {
  var formBody = [];
  for (var property in obj) {
    formBody.push(
      encodeURIComponent(property) + "=" + encodeURIComponent(obj[property])
    );
  }
  formBody = formBody.join("&");
  return formBody;
}

/**
 *aCompare deux objets par valeurs
/**/

export const object_comparer = (o1, o2) => {
  for (var key in o1) {
    if (o1[key] !== o2[key]) {
      return true;
    }
  }
  return false;
};
/**
 *
 * @param {shema} o1 fi
 * @returns object without html
 */
export const htmlSafe = (o1) => {
  return o1 ? o1.replace(/(<([^>]+)>)/gi, "") : "";
};

export const generateRandom = (maxLimit = 100) => {
  let rand = Math.random() * maxLimit;
  rand = Math.floor(rand);
  return rand;
};
export const randomDate = (start, end) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};

export const dynamicCompute = (operation, oldval = 0, value = "") => {
  let result = 0;
  switch (operation) {
    case "add":
      result = oldval ? Number(oldval) + Number(value) : Number(value);
      break;
    case "mine":
      result = oldval ? Number(oldval) - Number(value) : Number(value);
      break;
    default:
      break;
  }
  return result;
};

export function Numberise(str) {
  let regexRemover = /:("\d+")/gm;
  let match = regexRemover.exec(str);
  do {
    str = str.replace(match[1], match[1].replace(/\D+/g, ""));
  } while ((match = regexRemover.exec(str)) !== null);
  return str;
}

export const flatArrayBykey = (array = [], key = "", value = "") => {
  const result = array.filter((mes) => {
    if (Array.isArray(mes[key])) {
      return mes[key].findIndex((fi) => fi === value) !== -1;
    }
    return mes[key] === value;
  });
  if (result.length === 0 || !array[0][key]) {
    return array;
  }
  return result;
};

export const hasRole = (permission) => {
  const userPermissions = userroles.map((r) => r.value);
  userPermissions.push(...extraroles.map((r) => r.value));
  let _r = localStorage?.getItem(USER_INFOS);
  if (!_r) return false;
  try {
    _r = JSON.parse(_r)?.roles;
  } catch (error) {
    _r = [];
  }
  if (Array.isArray(permission)) {
    let found = _r.some((r) => permission.includes(r));
    return found;
  } else {
    for (let i = 0; i < _r.length; i++) {
      if (_r[i] === permission && userPermissions.includes(permission)) {
        return true;
      }
    }
  }
  return false;
};

export const hasPermission = (permission) => {
  let _r = localStorage?.getItem(USER_DATA);
  if (!_r) return false;
  try {
    _r = [...JSON.parse(_r)?.userAcls, ...JSON.parse(_r)?.roleAcls];
  } catch (error) {
    _r = [];
  }
  if (Array.isArray(permission)) {
    for (let pi = 0; pi < permission.length; pi++) {
      const [actionSlug, ressourceSlug] = permission[pi];
      let found = _r.findIndex(
        (r) => r.actionSlug === actionSlug && r.ressourceSlug === ressourceSlug
      );
      if (found !== -1) {
        return true;
      }
    }
  } else {
  }
  return false;
};

export const getUserData = (permission) => {
  const userdata = localStorage.getItem(USER_INFOS);
  return JSON.parse(userdata || "{}");
};

export const getUserInfo = (permission) => {
  const userdata = localStorage.getItem(USER_DATA);
  return JSON.parse(userdata || "{}");
};

export const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const groupArrBy = function (xs, first, config) {
  return xs.reduce(function (rv, x) {
    if (config && config.second) {
      (rv[x[first][config.second]] = rv[x[first][[config.second]]] || []).push(
        x
      );
    } else {
      (rv[x[first]] = rv[x[first]] || []).push(x);
    }
    return rv;
  }, {});
};

export const sortByDate = function (array, property, order) {
  return array.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    if (order === -1) {
      return new Date(a[property || "date"]) - new Date(b[property || "date"]);
    }
    return new Date(b[property || "date"]) - new Date(a[property || "date"]);
  });
};

export const getEventBullet = function (ev) {
  const indo = eventType.findIndex((mes) => mes.value === ev.etat);
  if (indo === -1) {
    return eventType[0].color;
  }
  return eventType[indo].color;
};

export const getEventStatusLabel = function (ev) {
  const indo = eventType.findIndex((mes) => mes.value === ev);
  if (indo === -1) {
    return eventType[0].label;
  }
  return eventType[indo].label;
};

export const getCampainStatusLabel = function (ev) {
  const indo = eventType.findIndex((mes) => mes.value === ev);
  if (indo === -1) {
    return eventType[0].label;
  }
  return eventType[indo].label;
};

export const uniquize = (res, po) => {
  const result = [];
  const map = new Map();
  for (const item of res) {
    if (!map.has(item[po])) {
      map.set(item[po], true);
      result.push(item);
    }
  }
  return result;
};
// => {"3": ["one", "two"], "5": ["three"]}

export const getMediaUrl = function (res) {
  return `${
    res?.contentUrl
      ? `${API_BASE_URL.replace("com/api", "com").replace("bj/api", "bj").replace("test/api", "test")}${
          res?.contentUrl
        }`
      : res //"/img/logo.png"
  }`;
  //
};

export const IriToId = function (mes) {
  return `${mes}`.substring(1 + `${mes}`.lastIndexOf("/"));
  //
};

/**
 *
 * @param {shema} o1 fi
 * @returns paginer une liste
 */
export const paginate = (array, page_size, page_number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};
/**
 *get start and end date of week month or year
 */
export const getMomentInterval = (moment = "week", date) => {
  let start, end;
  var curr = date ? new Date(date) : new Date();

  function daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }

  function getnumberfromString(txt) {
    var numb = txt.match(/\d/g);
    numb = numb.join("");
    return Number(numb);
  }

  switch (moment) {
    case "day":
      start = new Date(curr.setUTCHours(0, 0, 0, 0)).toUTCString();
      end = new Date(curr.setUTCHours(23, 59, 59, 999)).toUTCString();
      break;
    case "week":
      start = curr.getDate() - curr.getDay();
      end = start + 7;
      start = new Date(curr.setDate(start)).toISOString();
      end = new Date(curr.setDate(end)).toISOString();
      break;
    case "month":
      start = new Date(curr.setDate(1)).toISOString();
      end = new Date(
        curr.setDate(daysInMonth(curr.getMonth(), curr.getFullYear()))
      ).toISOString();
      break;
    case "year":
      start = new Date(curr.setMonth(0, 1)).toISOString();
      end = new Date(curr.setMonth(11, 31)).toISOString();
      break;
    default:
      start = curr.getDate() - getnumberfromString(moment);
      start = new Date(curr.setDate(start)).toISOString();
      end = new Date().toISOString();
      break;
  }
  return [start, end];
};

/**
 *get intervalle start and end date of week month or year
 */
export const getLastMomentInterval = (moment = "week", date) => {
  let start, end;
  function getnumberfromString(txt) {
    var numb = txt.match(/\d/g);
    numb = numb.join("");
    return Number(numb);
  }
  var curr = date ? new Date(date) : new Date();

  switch (moment) {
    case "month":
      end = new Date().toISOString();
      start = new Date(new Date().setDate(curr.getDate() - 30)).toISOString();
      break;
    default:
      let ints = getnumberfromString(moment);
      end = new Date().toISOString();
      start = new Date(new Date().setDate(curr.getDate() - ints)).toISOString();

      break;
  }
  return [start, end];
};
