/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { getUserData, getUserInfo } from "helpers/utils";
import { userroles } from "constants/properties.js";
import FetcherService from "services/FetcherService";

const menuItem = [
  {
    title: "Profil",
    icon: EditOutlined,
    path: "/app/settings/profile",
  },
  {
    title: "Paramètres",
    icon: SettingOutlined,
    path: "/app/settings/profile?configure=setting",
  },
  {
    title: "Documentation",
    icon: QuestionCircleOutlined,
    path: "/app/docs",
  },
];
export const NavProfile = ({ signOut }) => {
  const [centers, setcenters] = useState({
    member: [],
    totalItems: 1,
  });

  React.useEffect(() => {
    if (getUserInfo()?.agent?.id) {
      load_centers({ page: 1 });
    }
    return () => {};
  }, []);

  function load_centers(param) {
    FetcherService.GetCentreAgents({
      pagination: false,
      "agent.id": getUserInfo()?.agent?.id,
    })
      .then((res) => {
        if (!res.message) {
          let data = centers.member.concat(res["hydra:member"]);
          let memoizedCenters = {
            member: data,
            totalItems: res["hydra:totalItems"],
          };
          setcenters(memoizedCenters);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const userdata = getUserData();
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            icon={<UserOutlined color="black" style={{ color: "black" }} />}
            size={45}
          />
          <div className="pl-3">
            <h4 className="mb-0">{userdata.fullName} </h4>
            <span className="text-muted">
              {userdata.roles?.includes(userroles[1].value)
                ? "Administrateur"
                : "Opérateur"}
            </span>
          </div>
        </div>
        <div className="p-3">
          {[...centers.member].map((center, i) => {
            center = center.centre || center;
            return (
              <span>
                {center.libelle}
                {i !== centers.member.length - 1 ? ", " : ""}
              </span>
            );
          })}
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => {
              signOut();
            }}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Se déconnecter</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar
            icon={<UserOutlined color="black" style={{ color: "black" }} />}
          />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
