import { descriptions } from "constants/properties.js";
import { generateRandom, paginate, randomDate } from "helpers/utils";
import userData from "assets/data/user-list.data.json";

async function resolveresponse(obj) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve(obj);
    }, 3000);
  });
}
let FakeApi = {
  AuthSignin: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          token:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImN0eSI6IkpXVCJ9.eyJpYXQiOjE2Njg0NzI5MzQsImV4cCI6MTY2ODQ3NjUzNCwicm9sZXMiOlsiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6ImFkb2xwaGVAZ21haWwuY29tIiwiaXAiOiI0MS43OS4yMTkuMjIxIiwidHlwZSI6ImFkbWluIiwiZnVsbE5hbWUiOiJhZG9scGhlQGdtYWlsLmNvbSJ9.VWdh8-n4BR-5an3pxvc1bKObUPfwV-7T3uWeRid_7gX9EeWmqC4W1R43rrYvoz3zdD1u3FNURfr1_W0OPMoO_HbS2wKJTfjvCelx2xH7EOORT8GCX74Xdxk2k1MbshPLXcFmJDczZh3WZ70q-fCONfoJicZheO0CpDxhffwkEvd85Y6qWCI0hMxk021zVzZ2je73JG65jgGZxpR0GLalzyFmhQAdTKUiqoKq-c9IdGGDjri9AqQVJHrznU_KexF10bM2_LCW5_FikUMXKd7cxDtDrNrZT333ssfqVbNlbDndybL4rmA8MfvJ15jFm2glPJdCD0ftyjaXOTZywrnehg",
        });
      }, 3000);
    });
    return await result;
  },
  GetUserData: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          token: "fake_token_jnsldfnsmdjfbkfsdbsjqkbdvlqjsdkvbqd",
          ...setdada,
        });
      }, 3000);
    });
    return await result;
  },
  GetUsers: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          totalItems: 202,
          member: userData,
        });
      }, 3000);
    });
    return await result;
  },
  GetPublications: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          totalItems: 202,
          member: Array.apply(null, Array(30)).map(function (x, i) {
            return {
              id: `497f6eca-6276-4993-bfeb-53cbbbba6f08${i}`,
              title:
                "Campagne don de sang : Benjamin HOUNKPATIN lance la grande mobilisation",
              content: descriptions[generateRandom(descriptions.length)],
              createdAt: "2019-08-24T14:15:22Z",
              updatedAt: "2019-08-24T14:15:22Z",
              isPublished: true,
              isPublishedAt: "2019-08-24T14:15:22Z",
              toSendByNewLetter: true,
              isAlreadySent: true,
              media:
                "https://www.gouv.bj/upload/images/articles/ckeditor/IMG-20210718-WA0048.jpg",
              createdBy: "Anianou Gbo Adolphe",
              category: "Information utile",
              slug: "string",
            };
          }),
        });
      }, 3000);
    });
    return await result;
  },
  GetMessages: async function ({ page }) {
    const destos = ["opposite", "me"];
    const data = Array.apply(null, Array(255)).map(function (x, i) {
      return {
        "@id": "/api/messages/e52c9fbb-caaa-4cbf-85cb-8c4778d868ee",
        "@type": "Message",
        fakeID: i,
        fakeday: Date.now() + i,
        objet: "Je veux donner du sang ",
        contenu: `Aidez moi  donner du sang svp${i}`,
        sender: {
          "@id": "/api/users/ef66cb9a-fb8b-42c7-9651-6778a8c3cffa",
          "@type": "User",
          id: "ef66cb9a-fb8b-42c7-9651-6778a8c3cffa",
          username: "96626262",
          type: "donneur",
        },
        lu: false,
        from: i % 2 === 0 ? destos[0] : destos[1],
        centre: "/api/centres/3bbac8fd-734c-478e-b966-dd3b3497ec61",
        conversation: {
          "@context": "string",
          "@id": "string",
          "@type": "string",
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          createdBy: "string",
          createdAt: "2022-11-22T11:24:41.626Z",
          objet: "Je veux donner du sang ",
        },
      };
    });

    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          "hydra:totalItems": data.length,
          "hydra:member": paginate(data, 10, page),
        });
      }, 3000);
    });
    return await result;
  },
  GetParticipentEvent: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          totalItems: 30,
          "hydra:member": Array.apply(null, Array(30)).map(function (x, i) {
            return {
              id: `497f6eca-6276-4993-bfeb-53cbbbba6f08${i}`,
              object:
                "Campagne don de sang : Benjamin HOUNKPATIN lance la grande mobilisation",
              content: descriptions[generateRandom(descriptions.length)],
              createdAt: "2019-08-24T14:15:22Z",
              updatedAt: "2019-08-24T14:15:22Z",
              isPublished: true,
              isPublishedAt: "2019-08-24T14:15:22Z",
              toSendByNewLetter: true,
              isAlreadySent: true,
              media:
                "https://www.gouv.bj/upload/images/articles/ckeditor/IMG-20210718-WA0048.jpg",
              createdBy: "Anianou Gbo Adolphe",
              category: "Information utile",
              slug: "string",

              "@context": "string",
              "@id": "string",
              "@type": "string",
              confirmed: Math.random() > 0,
              dateConfirmation: "2019-08-24T14:15:22Z",
              confirmedBy: {
                "@context": "string",
                "@id": "string",
                "@type": "string",
                id: "497f6eca-6276-4993-bfeb-53cbbbba6f08",
                username: "tootli",
                fiche: {
                  "@context": "string",
                  "@id": "string",
                  "@type": "string",
                  id: "497f6eca-6276-4993-bfeb-53cbbbba6f08",
                  firstName: "tootli",
                  lastName: "Roominus",
                  telephone: "94632954",
                },
              },
            };
          }),
        });
      }, 3000);
    });
    return await result;
  },
  testerz: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          id: `497f6eca-6276-4993-bfeb-53cbbbba6f08`,
          title:
            "Campagne don de sang : Benjamin HOUNKPATIN lance la grande mobilisation",
          content: descriptions[generateRandom(descriptions.length)],
          createdAt: "2019-08-24T14:15:22Z",
          updatedAt: "2019-08-24T14:15:22Z",
          isPublished: true,
          isPublishedAt: "2019-08-24T14:15:22Z",
          toSendByNewLetter: true,
          isAlreadySent: true,
          media:
            "https://www.gouv.bj/upload/thumbnails/articles/0752391001666712915.jpeg",
          createdBy: "Anianou Gbo Adolphe",
          category: "Information utile",
          slug: "string",
        });
      }, 3000);
    });
    return await result;
  },

  GetPublication: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          new: 1,
          description: descriptions[generateRandom(descriptions.length)],
          id: `497f6eca-6276-4993-bfeb-53cbbbba6f08${Date.now()}`,
          title:
            "Campagne don de sang : Benjamin HOUNKPATIN lance la grande mobilisation",
          content: descriptions[generateRandom(descriptions.length)],
          createdAt: "2019-08-24T14:15:22Z",
          updatedAt: "2019-08-24T14:15:22Z",
          isPublished: true,
          isPublishedAt: "2019-08-24T14:15:22Z",
          toSendByNewLetter: true,
          isAlreadySent: true,
          media:
            "https://www.gouv.bj/upload/images/articles/ckeditor/IMG-20210718-WA0048.jpg",
          createdBy: "Anianou Gbo Adolphe",
          category: "Information utile",
          slug: "string",
        });
      }, 3000);
    });
    return await result;
  },

  GetUserAnswers: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          new: 1,
          donnorNumber: 64646656,
          lastName: "MINTCHEWE",
          firstName: "Edouard T. Vincent",
          description: descriptions[generateRandom(descriptions.length)],
          id: `497f6eca-6276-4993-bfeb-53cbbbba6f08${Date.now()}`,
          title:
            "Campagne don de sang : Benjamin HOUNKPATIN lance la grande mobilisation",
          content: descriptions[generateRandom(descriptions.length)],
          isPublished: true,
          isPublishedAt: "2019-08-24T14:15:22Z",
          toSendByNewLetter: true,
          isAlreadySent: true,
          dateNaissance: "2000-02-25T07:22:18+00:00",
          createdAt: "2022-11-25T07:22:18+00:00",
          updatedAt: "2022-11-25T07:22:18+00:00",
          firstDonDate: "2021-11-25T07:22:18+00:00",
          totalDon: 8,
          antenne: "AGONTINKON",
          media: {
            "@id": "/api/media_objects/b1810f0a-1092-4367-99b9-24dde43fe6bf",
            "@type": "https://schema.org/MediaObject",
            contentUrl: "/media/63806d2847da5_art726.jpeg",
          },
          createdBy: {
            "@id": "/api/users/cf87a283-4b19-43ce-94c2-ffe28cf32c29",
            "@type": "User",
            id: "cf87a283-4b19-43ce-94c2-ffe28cf32c29",
            username: "benitto@gmail.com",
          },
          category: {
            "@id":
              "/api/category_publications/15059fcf-f896-467a-82a0-354afd5c13a4",
            "@type": "CategoryPublication",
            id: "15059fcf-f896-467a-82a0-354afd5c13a4",
            name: "VIH",
          },
          slug: "vaccination-contre-la-poliomyelite-les-clarifications-du-gouvernement",
        });
      }, 3000);
    });
    return await result;
  },

  GetPublicationsCategory: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          [
            "Documentations",
            "Actualités",
            "Comuniqués",
            "Emplois",
            "Campagne",
          ].map((mes) => {
            return {
              id: mes,
              name: mes,
              isActivated: true,
              isDeleted: true,
              publications: [],
            };
          })
        );
      }, 3000);
    });
    return await result;
  },
  RessetPassword: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          success_message:
            "Si vous avez saisit la bonne adresse mail, des instructions vous sont envoyées dans votre boîte Mail.",
        });
      }, 3000);
    });
    return await result;
  },
  ChangePassPassu: async function (setdada) {
    //{new_pass,password_conf,password}
    //=>{error,success}
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          success:
            "Si vous avez saisit la bonne adresse mail, des instructions vous sont envoyées dans votre boîte Mail.",
        });
      }, 3000);
    });
    return await result;
  },
  PostFiche: async function (setdada) {},
  PostBeneficiaire: async function (setdada) {},
  GetFiches: async function (data) {},
  /*
   Storer.getData("@SAVED_NOTES").then((dati) => {
              if (dati) {
                let notes = dati.find((e) => e.id == id);
                notes.content = notes.content.filter((e) => e.id != note.id);
                dati = dati.filter((e) => e.id != id);
                dati.push(notes);
                Storer.storeData("@SAVED_NOTES", dati);
              }
              ToastAndroid.show(
                Globals.STRINGS.suceffully_delete,
                ToastAndroid.LONG
              );
              navigation.pop();
            });
*/
  /**
 GetCollecteurs: async function (data, cache) {
    //=> {error,collecteurs}
    let result = {};
    if (cache) {
      let cachedData = await Storer.getData('@Collecteurs');
      if (cachedData) {
        result = cachedData;
      }
    } else {
      result = await resolveresponse({
        collecteurs: Array.apply(null, Array(30)).map(function (x, i) {
          return {
            id: i + 1,
            new: 1,
            name: collecteurs[generateRandom(collecteurs.length)],
            description: descriptions[generateRandom(descriptions.length)],
            urlPhoto: 'https://picsum.photos/200',
          };
        }),
      });
    }
    return result;
  },
   */
  GetCollecteurs: async function (data, cache) {
    //=> {error,collecteurs}
    return await resolveresponse({
      collecteurs: Array.apply(null, Array(30)).map(function (x, i) {
        return {
          id: i + 1,
          new: 1,
          // name: collecteurs[generateRandom(collecteurs.length)],
          // description: descriptions[generateRandom(descriptions.length)],
          urlPhoto: "https://picsum.photos/200",
        };
      }),
    });
  },
  GetBeneficiaires: async function (data, cache) {
    //=> {error,beneficiaires}
    return await resolveresponse({
      beneficiaires: Array.apply(null, Array(30)).map(function (x, i) {
        return {
          id: i + 1,
          new: 1,
          // name: collecteurs[generateRandom(collecteurs.length)],
          // description: descriptions[generateRandom(descriptions.length)],
          urlPhoto: "https://picsum.photos/200",
        };
      }),
    });
  },
  Getformations: async function (data, cache) {
    //=> {error,formations}
    return await resolveresponse({
      formations: Array.apply(null, Array(30)).map(function (x, i) {
        return {
          id: i + 1,
          // libelle: formations[generateRandom(formations.length)],
          dateDebut: randomDate(new Date(2022, 0, 1), new Date()),
          dateFin: randomDate(new Date(2022, 0, 1), new Date()),
          formationCentres: [
            // `${arrondissements[generateRandom(arrondissements.length)].label},${
            // communes[generateRandom(communes.length)].label
            // }`,
          ],
        };
      }),
    });
  },
  Getkits: async function (data, cache) {
    //=> {error,kits}
    return await resolveresponse({
      kits: Array.apply(null, Array(30)).map(function (x, i) {
        return {
          id: i + 1,
          // libelle: kits[generateRandom(kits.length)],
        };
      }),
    });
  },
  UpdateData: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          sucess_Update: "Modification reuissi !",
          prenom: "Mathildda",
          nom: "Martica",
          address: "Abomey Calavi",
          contact: "94632954",
          adminId: 50,

          ficheRemplies: 68,
          ficheRejetes: 80,
          nbrBeneficiaire: 8,
          ficheAttente: 15,
          new_messages: 20,
          new_notifications: 15,
        });
      }, 3000);
    });
    return await result;
  },
  Signout: async function (setdada) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: 1,
        });
      }, 3000);
    });
    return await result;
  },
  CheckAuth: async function (setdada, tk) {
    const result = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          saved: 5,
          checked: 15,
          rejected: 20,
          earned: 30,
        });
      }, 3000);
    });
    return await result;
  },
};
export default FakeApi;
