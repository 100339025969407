import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { hasPermission, hasRole } from "helpers/utils";

export function RouteInterceptor({
  Component,
  canseeit,
  permissions,
  ...rest
}) {
  let _r = localStorage?.getItem(canseeit);
  return (
    <Route
      {...rest}
      render={({ location, match }) => {
        let go = false;
        if (typeof permissions === "boolean") {
          go = permissions;
        } else if (permissions && Array.isArray(permissions)) {
          go = hasRole(permissions);
        } else {
          if (
            typeof canseeit === "string" &&
            _r &&
            typeof _r === "string" &&
            _r.length > 2
          )
            go = true;
          else if (Array.isArray(canseeit)) {
            for (let i = 0; i < canseeit.length; i++) {
              const havePermission = localStorage?.getItem(canseeit[i]);
              if (
                havePermission &&
                typeof havePermission === "string" &&
                havePermission.length > 2
              ) {
                go = true;
              }
            }
          }
        }
        return go ? (
          <Component match={match} location={location} />
        ) : (
          <Redirect
            to={{
              pathname: `${AUTH_PREFIX_PATH}/error-2`,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <RouteInterceptor
          path={`${APP_PREFIX_PATH}/dashboards`}
          permissions={true}
          Component={lazy(() => import(`./dashboards/dashboards`))}
        />

        <RouteInterceptor
          permissions={["ROLE_ADMIN", "ROLE_AGENT_ANTS", "ROLE_MONITEUR"]}
          path={`${APP_PREFIX_PATH}/statistics`}
          Component={lazy(() => import(`./statistics/statistics`))}
        />

        <RouteInterceptor
          permissions={
            hasRole(["ROLE_AGENT_ANTS", "ROLE_ENCODEUR"]) ||
            hasPermission([
              ["READ", "Prelevements"],
              ["READ", "Examens"],
              ["READ", "ResultatExamens"],
            ])
          }
          path={`${APP_PREFIX_PATH}/encodage`}
          Component={lazy(() => import(`./encodage/encodage`))}
        />

        <RouteInterceptor
          permissions={
            hasRole([
              "ROLE_ADMIN",
              "ROLE_AGENT_ANTS",
              "ROLE_GEST_USER",
              "ROLE_GEST_AGENT",
            ]) || hasPermission([["READ", "User"]])
          }
          path={`${APP_PREFIX_PATH}/users`}
          Component={lazy(() => import(`./users/users`))}
        />

        <RouteInterceptor
          path={`${APP_PREFIX_PATH}/extras`}
          permissions={
            hasRole(["ROLE_ADMIN", "ROLE_ENCODEUR", "ROLE_TECHNO_STRUCTURE"]) ||
            hasPermission([
              ["READ", "Fonction"],
              ["READ", "GroupeRare"],
              ["READ", "Alerte"],
              ["READ", "Examens"],
              ["READ", "ResultatExamens"],
              ["READ", "TypeExamen"],
              ["READ", "Roles"],
              ["READ", "RoleAcl"],
              ["READ", "RaisonAnnulation"],
              ["READ", "RessourceActions"],
            ])
          }
          Component={lazy(() => import(`./extras/extras`))}
        />

        <RouteInterceptor
          path={`${APP_PREFIX_PATH}/conversations`}
          permissions={
            hasRole(["ROLE_ADMIN", "ROLE_MESSAGE"]) ||
            hasPermission([["READ", "Message"]])
          }
          Component={lazy(() => import(`./conversations/conversations`))}
        />

        <RouteInterceptor
          path={`${APP_PREFIX_PATH}/questions`}
          permissions={
            hasRole(["ROLE_ADMIN", "ROLE_PROFESSEUR"]) ||
            hasPermission([
              ["READ", "ThemeQuiz"],
              ["READ", "Quiz"],
            ])
          }
          Component={lazy(() => import(`./quizzes/quizzes`))}
        />

        <RouteInterceptor
          path={`${APP_PREFIX_PATH}/emplacements`}
          permissions={
            hasRole(["ROLE_ADMIN", "ROLE_TERRIEN"]) ||
            hasPermission([
              ["READ", "Localite"],
              ["READ", "Departement"],
              ["READ", "Centre"],
            ])
          }
          Component={lazy(() => import(`./emplacements/emplacements`))}
        />
        <RouteInterceptor
          path={`${APP_PREFIX_PATH}/faqs`}
          permissions={
            hasRole(["ROLE_ADMIN", "ROLE_PROFESSEUR"]) ||
            hasPermission([
              ["READ", "CategoryFaq"],
              ["READ", "Faq"],
            ])
          }
          Component={lazy(() => import(`./faqs/faqs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./settings/settings`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/docs`}
          component={lazy(() => import(`./docs/documentations`))}
        />

        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboards`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
