export const variants = [
  "226, 72 ,65",
  "99, 75, 216",
  "121 ,220 ,71",
  "61 ,153 ,245",
],
  icons = ["file1", "filetext1", "profile", "file-markdown"],
  descriptions = [
    "Il faut cependant attendre les élections législatives françaises de 1951 pour que la rupture soit effective. L’obtention d’un second siège au Palais Bourbon pour la circonscription du Dahomey est alors considérée, par Hubert Maga et ses partisans, comme l’occasion donnée à l’UPD de démontrer son attachement aux intérêts du",
    "En termes de fréquentation scolaire, 45 % des filles du premier cycle du secondaire ne sont pas scolarisées (contre 28 % des garçons).",
    "Si cette dynamique démographique se poursuit, la population béninoise pourrait doubler au cours des 30 prochaines années",
    "ce qui entraînerait une pression accrue sur les dépenses sociales et économiques et risquerait de retarder davantage la transition",
    `La perte de connexion est un problème majeur auquel sont confrontés les utilisateurs du web depuis des années. La meilleure application web au monde produirait un bien mauvais effet si vous ne pouviez pas la télécharger. Il y a eu de nombreuses tentatives pour mettre en place des technologies susceptibles de résoudre ce problème, comme le montre notre page Offline, et certains problèmes ont été résolus. Mais le problème principal reste l'absence d'un mécanisme de contrôle global pour la mise en cache des ressources et la personnalisation des requêtes réseau.

        La première tentative— AppCache — semblait être une bonne idée parce qu'elle permettait de spécifier les ressources à mettre en cache de manière très simple. Cependant, elle faisait beaucoup de suppositions sur la manière de le mettre en place et échouait implacablement si votre application ne se pliait pas exactement à ces suppositions. Lisez Application Cache is a Douchebag de Jake Archibald pour plus de détails.`,

    `Le Ministre de la santé Benjamin HOUNKPATIN a procédé au lancement de la campagne de don de sang destinée à renforcer la disponibilité du   sang dans les formations sanitaires. Il s’est prêté à l’exercice de don de sang le vendredi 16 juillet 2021 au Ministère de la santé.  

En donnant de son sang, le Ministre de la santé a manifesté son engagement à s’investir résolument dans le plaidoyer national en vue d’une disponibilité accrue du liquide vital dans les banques de sang. Il a sonné la mobilisation générale à travers un appel à toute la population âgée de 18 à 65 ans. « Je lance un appel à toute la population âgée de 18 à 65 pesant 50 kilogrammes et ne présentant aucune affection qui contre indique le don de sang, à sortir massivement et à donner son sang. Ce don de sang peut être effectué tous les 3 mois pour les hommes et tous les 4 mois pour les femmes », a-t-il laissé entendre. La disponibilité du sang reste un défi permanent que seul l’engagement de chacun pourra contribuer à relever. 

Dans son adresse, le Ministre de la santé est revenu sur l’importance du don de sang au profit des cibles prioritaires que sont les enfants de 0 à 5 ans et les femmes enceintes. « En donnant notre sang, nous sauvons plusieurs vies. La pénurie a commencé au niveau de nos formations sanitaires surtout avec les pluies. Au niveau des services de pédiatrie, les fréquentations d’enfants qui souffrent de paludisme anémique a déjà commencé. Il est très important que nous continuons à assurer la disponibilité du sang pour que chaque enfant, chaque femme enceinte puisse en bénéficier », a déclaré le Ministre de la santé.

`,
  ],
  titles = [
    "pour que la rupture soit effective. L’obtention d’un second siège au Palais Bourbon pour la circonscription du Dahomey est alors considérée, par Hubert Maga et ses partisans, comme l’occasion donnée à l’UPD de démontrer son attachement aux intérêts du",
    "En termes de fréquentation scolaire, 45 % des filles du premier cycle du secondaire ne sont pas scolarisées (contre 28 % des garçons).",
    "Si cette dynamique démographique se poursuit, la population béninoise pourrait doubler au cours des 30 prochaines années",
    "ce qui entraînerait une pression accrue sur les dépenses sociales et économiques et risquerait de retarder davantage la transition",
    `La perte de connexion est un problème majeur auquel sont confrontés les utilisateurs du web depuis des années. La meilleure application web au monde produirait un bien mauvais effet si vous ne pouviez pas la télécharger. Il y a eu de nombreuses tentatives pour mettre en place des technologies susceptibles de résoudre ce problème, comme le montre notre page Offline, et certains problèmes ont été résolus. Mais le problème principal reste l'absence d'un mécanisme de contrôle global pour la mise en cache des ressources et la personnalisation des requêtes réseau.

        La première tentative— AppCache — semblait être une bonne idée parce qu'elle permettait de spécifier les ressources à mettre en cache de manière très simple. Cependant, elle faisait beaucoup de suppositions sur la manière de le mettre en place et échouait implacablement si votre application ne se pliait pas exactement à ces suppositions. Lisez Application Cache is a Douchebag de Jake Archibald pour plus de détails.`,
  ],
  periodeComposition = [
    {
      label: "Première composition",
      value: "premiere_composition",
    },
    {
      label: "Deuxième composition",
      value: "deuxieme_composition",
    },
    {
      label: "Troisième composition",
      value: "troisieme_composition",
    },
    {
      label: "Composition de passage",
      value: "composition_de_passage",
    },
  ],
  confessionReligieux = [
    {
      label: "Catholique",
      value: "catholique",
    },
    {
      label: "Musulman",
      value: "musulman",
    },
    {
      label: "Protestant",
      value: "protestant",
    },
    {
      label: "Christianiste céleste",
      value: "christianiste_celeste",
    },
    {
      label: "Dignitaire Vodoun",
      value: "dignitaire_vodoun",
    },
  ],
  collecteurs = [
    "ASSIAMAIN Nikola",
    "Minsmin Nikole",
    "Blaise YIDENON",
    "Antoine GLELE",
    "KOKOU Niquèze",
    "Reginald KOMLAN",
    "TIPENTI gaspard",
  ],
  formations = [
    "necessité de protection",
    "prevention vih",
    "propreté en milueu scolaire",
    "la puberté chez elles",
  ],
  fichestates = ["review", "accepted", "rejected"],
  niveauEtude = [
    {
      label: "Primaire",
      value: "primaire",
    },
    {
      label: "Secondaire",
      value: "secondaire",
    },
  ],
  cycles = [
    {
      label: "Primaire",
      value: "primaire",
      niveau: "primaire",
    },
    {
      label: "Premier cycle",
      value: "premier_cycle",
      niveau: "secondaire",
    },
    {
      label: "Second  cycle",
      value: "second_cycle",
      niveau: "secondaire",
    },
  ],
  classes = [
    {
      label: "CM1",
      value: "cm1",
      cycle: "primaire",
    },
    {
      label: "CM2",
      value: "cm2",
      cycle: "primaire",
    },
    {
      label: "6ème",
      value: "6_me",
      cycle: "premier_cycle",
    },
    {
      label: "5ème",
      value: "5_me",
      cycle: "premier_cycle",
    },
    {
      label: "4ème",
      value: "4_me",
      cycle: "premier_cycle",
    },
    {
      label: "3ème",
      value: "3_me",
      cycle: "premier_cycle",
    },
    {
      label: "2nd",
      value: "2nd",
      cycle: "second_cycle",
    },
    {
      label: "1ère",
      value: "1_re",
      cycle: "second_cycle",
    },
    {
      label: "Tle",
      value: "tle",
      cycle: "second_cycle",
    },
  ],
  series = [
    {
      label: "A",
      value: "a",
      cycle: "second_cycle",
    },
    {
      label: "B",
      value: "b",
      cycle: "second_cycle",
    },
    {
      label: "C",
      value: "c",
      cycle: "second_cycle",
    },
    {
      label: "D",
      value: "d",
      cycle: "second_cycle",
    },
  ],
  critereVulnerabilites = [
    {
      label: "OEV",
      value: "oev",
    },
    {
      label: "PLACEE",
      value: "placee",
    },
    {
      label: "SURVIVANTE VBG",
      value: "survivante_vbg",
    },
    {
      label: "FILLE - MERE",
      value: "fille___mere",
    },
    {
      label: "HANDICAPEE",
      value: "handicapee",
    },
  ],
  periodescolaire = [
    {
      label: "Premier semestre",
      value: "premier_semestre",
    },
    {
      label: "Second semestre",
      value: "second_semestre",
    },
    {
      label: "Annuelle ",
      value: "annuelle",
    },
  ],
  decisionFinaleEtablissement = [
    {
      label: "La fille a validé l'année",
      value: "la_fille_a_valid__l_ann_e",
    },
    {
      label: "La fille reprend la classe",
      value: "la_fille_reprend_la_classe",
    },
  ],
  frequenceCours = [
    {
      label: "Très fréquente",
      value: "tr_s_fr_quente",
    },
    {
      label: "Fréquente",
      value: "fr_quente",
    },
    {
      label: "Moyennement fréquente",
      value: "moyennement_fr_quente",
    },
    {
      label: "Peu fréquente",
      value: "peu_fr_quente",
    },
    {
      label: "Pas du tout fréquente",
      value: "pas_du_tout_fr_quente",
    },
    {
      label: "Abandon",
      value: "abandon",
    },
  ],
  departements = [
    {
      label: "ALIBORI",
      value: "1",
    },
    {
      label: "ATACORA",
      value: "2",
    },
    {
      label: "ATLANTIQUE",
      value: "3",
    },
    {
      label: "BORGOU",
      value: "4",
    },
    {
      label: "COLLINES",
      value: "5",
    },
    {
      label: "COUFFO",
      value: "6",
    },
    {
      label: "DONGA",
      value: "7",
    },
    {
      label: "LITTORAL",
      value: "8",
    },
    {
      label: "MONO",
      value: "9",
    },
    {
      label: "OUÉMÉ",
      value: "10",
    },
    {
      label: "PLATEAU",
      value: "11",
    },
    {
      label: "ZOU",
      value: "12",
    },
  ],
  communes = [
    {
      DEPARTEMENTS: "1",
      label: "BANIKOARA",
      value: "1",
    },
    {
      DEPARTEMENTS: "1",
      label: "GOGOUNOU",
      value: "2",
    },
    {
      DEPARTEMENTS: "1",
      label: "KANDI",
      value: "3",
    },
    {
      DEPARTEMENTS: "1",
      label: "KARIMAMA",
      value: "4",
    },
    {
      DEPARTEMENTS: "1",
      label: "MALANVILLE",
      value: "5",
    },
    {
      DEPARTEMENTS: "1",
      label: "SEGBANA",
      value: "6",
    },
    {
      DEPARTEMENTS: "2",
      label: "BOUKOUMBÉ",
      value: "7",
    },
    {
      DEPARTEMENTS: "2",
      label: "COBLY",
      value: "8",
    },
    {
      DEPARTEMENTS: "2",
      label: "KÉROU",
      value: "9",
    },
    {
      DEPARTEMENTS: "2",
      label: "KOUANDÉ",
      value: "10",
    },
    {
      DEPARTEMENTS: "2",
      label: "MATÉRI",
      value: "11",
    },
    {
      DEPARTEMENTS: "2",
      label: "NATITINGOU",
      value: "12",
    },
    {
      DEPARTEMENTS: "2",
      label: "PÉHUNCO",
      value: "13",
    },
    {
      DEPARTEMENTS: "2",
      label: "TANGUIÉTA",
      value: "14",
    },
    {
      DEPARTEMENTS: "2",
      label: "TOUCOUNTOUNA",
      value: "15",
    },
    {
      DEPARTEMENTS: "3",
      label: "ABOMEY-CALAVI",
      value: "16",
    },
    {
      DEPARTEMENTS: "3",
      label: "ALLADA",
      value: "17",
    },
    {
      DEPARTEMENTS: "3",
      label: "KPOMASSÈ",
      value: "18",
    },
    {
      DEPARTEMENTS: "3",
      label: "OUIDAH",
      value: "19",
    },
    {
      DEPARTEMENTS: "3",
      label: "SÔ-AVA",
      value: "20",
    },
    {
      DEPARTEMENTS: "3",
      label: "TOFFO",
      value: "21",
    },
    {
      DEPARTEMENTS: "3",
      label: "TORI-BOSSITO",
      value: "22",
    },
    {
      DEPARTEMENTS: "3",
      label: "ZÈ",
      value: "23",
    },
    {
      DEPARTEMENTS: "4",
      label: "BEMBÉRÉKÉ",
      value: "24",
    },
    {
      DEPARTEMENTS: "4",
      label: "KALALÉ",
      value: "25",
    },
    {
      DEPARTEMENTS: "4",
      label: "N'DALI",
      value: "26",
    },
    {
      DEPARTEMENTS: "4",
      label: "NIKKI",
      value: "27",
    },
    {
      DEPARTEMENTS: "4",
      label: "PARAKOU",
      value: "28",
    },
    {
      DEPARTEMENTS: "4",
      label: "PÈRÈRÈ",
      value: "29",
    },
    {
      DEPARTEMENTS: "4",
      label: "SINENDÉ",
      value: "30",
    },
    {
      DEPARTEMENTS: "4",
      label: "TCHAOUROU",
      value: "31",
    },
    {
      DEPARTEMENTS: "5",
      label: "BANTÈ",
      value: "32",
    },
    {
      DEPARTEMENTS: "5",
      label: "DASSA-ZOUMÈ",
      value: "33",
    },
    {
      DEPARTEMENTS: "5",
      label: "GLAZOUÉ",
      value: "34",
    },
    {
      DEPARTEMENTS: "5",
      label: "OUÈSSÈ",
      value: "35",
    },
    {
      DEPARTEMENTS: "5",
      label: "SAVALOU",
      value: "36",
    },
    {
      DEPARTEMENTS: "5",
      label: "SAVÈ",
      value: "37",
    },
    {
      DEPARTEMENTS: "6",
      label: "APLAHOUÉ",
      value: "38",
    },
    {
      DEPARTEMENTS: "6",
      label: "DJAKOTOMEY",
      value: "39",
    },
    {
      DEPARTEMENTS: "6",
      label: "DOGBO",
      value: "40",
    },
    {
      DEPARTEMENTS: "6",
      label: "KLOUÉKANMÈ",
      value: "41",
    },
    {
      DEPARTEMENTS: "6",
      label: "LALO",
      value: "42",
    },
    {
      DEPARTEMENTS: "6",
      label: "TOVIKLIN",
      value: "43",
    },
    {
      DEPARTEMENTS: "7",
      label: "BASSILA",
      value: "44",
    },
    {
      DEPARTEMENTS: "7",
      label: "COPARGO",
      value: "45",
    },
    {
      DEPARTEMENTS: "7",
      label: "DJOUGOU",
      value: "46",
    },
    {
      DEPARTEMENTS: "7",
      label: "OUAKÉ",
      value: "47",
    },
    {
      DEPARTEMENTS: "8",
      label: "COTONOU",
      value: "48",
    },
    {
      DEPARTEMENTS: "9",
      label: "ATHIÉMÉ",
      value: "49",
    },
    {
      DEPARTEMENTS: "9",
      label: "BOPA",
      value: "50",
    },
    {
      DEPARTEMENTS: "9",
      label: "COMÈ",
      value: "51",
    },
    {
      DEPARTEMENTS: "9",
      label: "GRAND-POPO",
      value: "52",
    },
    {
      DEPARTEMENTS: "9",
      label: "HOUÉYOGBÉ",
      value: "53",
    },
    {
      DEPARTEMENTS: "9",
      label: "LOKOSSA",
      value: "54",
    },
    {
      DEPARTEMENTS: "10",
      label: "ADJARRA",
      value: "55",
    },
    {
      DEPARTEMENTS: "10",
      label: "ADJOHOUN",
      value: "56",
    },
    {
      DEPARTEMENTS: "10",
      label: "AGUÉGUÉS",
      value: "57",
    },
    {
      DEPARTEMENTS: "10",
      label: "AKPRO-MISSÉRÉTÉ",
      value: "58",
    },
    {
      DEPARTEMENTS: "10",
      label: "AVRANKOU",
      value: "59",
    },
    {
      DEPARTEMENTS: "10",
      label: "BONOU",
      value: "60",
    },
    {
      DEPARTEMENTS: "10",
      label: "DANGBO",
      value: "61",
    },
    {
      DEPARTEMENTS: "10",
      label: "PORTO-NOVO",
      value: "62",
    },
    {
      DEPARTEMENTS: "10",
      label: "SÈMÈ-KPODJI",
      value: "63",
    },
    {
      DEPARTEMENTS: "11",
      label: "ADJA-OUÈRÈ",
      value: "64",
    },
    {
      DEPARTEMENTS: "11",
      label: "IFANGNI",
      value: "65",
    },
    {
      DEPARTEMENTS: "11",
      label: "KÉTOU",
      value: "66",
    },
    {
      DEPARTEMENTS: "11",
      label: "POBÈ",
      value: "67",
    },
    {
      DEPARTEMENTS: "11",
      label: "SAKÉTÉ",
      value: "68",
    },
    {
      DEPARTEMENTS: "12",
      label: "ABOMEY",
      value: "69",
    },
    {
      DEPARTEMENTS: "12",
      label: "AGBANGNIZOUN",
      value: "70",
    },
    {
      DEPARTEMENTS: "12",
      label: "BOHICON",
      value: "71",
    },
    {
      DEPARTEMENTS: "12",
      label: "COVÈ",
      value: "72",
    },
    {
      DEPARTEMENTS: "12",
      label: "DJIDJA",
      value: "73",
    },
    {
      DEPARTEMENTS: "12",
      label: "OUINHI",
      value: "74",
    },
    {
      DEPARTEMENTS: "12",
      label: "ZANGNANADO",
      value: "75",
    },
    {
      DEPARTEMENTS: "12",
      label: "ZA-KPOTA",
      value: "76",
    },
    {
      DEPARTEMENTS: "12",
      label: "ZOGBODOMEY",
      value: "77",
    },
  ],
  arrondissements = [
    {
      COMMUNES: "1",
      label: "BANIKOARA",
      value: "1",
    },
    {
      COMMUNES: "1",
      label: "FOUNOUGO",
      value: "2",
    },
    {
      COMMUNES: "1",
      label: "GOMPAROU",
      value: "3",
    },
    {
      COMMUNES: "1",
      label: "GOUMORI",
      value: "4",
    },
    {
      COMMUNES: "1",
      label: "KOKEY",
      value: "5",
    },
    {
      COMMUNES: "1",
      label: "KOKIBOROU",
      value: "6",
    },
    {
      COMMUNES: "1",
      label: "OUNET",
      value: "7",
    },
    {
      COMMUNES: "1",
      label: "SOMPÉRÉKOU",
      value: "8",
    },
    {
      COMMUNES: "1",
      label: "SOROKO",
      value: "9",
    },
    {
      COMMUNES: "1",
      label: "TOURA",
      value: "10",
    },
    {
      COMMUNES: "2",
      label: "BAGOU",
      value: "11",
    },
    {
      COMMUNES: "2",
      label: "GOGOUNOU",
      value: "12",
    },
    {
      COMMUNES: "2",
      label: " GOUNAROU ",
      value: "13",
    },
    {
      COMMUNES: "2",
      label: "OUARA",
      value: "14",
    },
    {
      COMMUNES: "2",
      label: "SORI",
      value: "15",
    },
    {
      COMMUNES: "2",
      label: " ZOUNGOU-PANTROSSI",
      value: "16",
    },
    {
      COMMUNES: "3",
      label: "ANGARADÉBOU",
      value: "17",
    },
    {
      COMMUNES: "3",
      label: "BENSÉKOU",
      value: "18",
    },
    {
      COMMUNES: "3",
      label: "DONWARI",
      value: "19",
    },
    {
      COMMUNES: "3",
      label: " KANDI I",
      value: "20",
    },
    {
      COMMUNES: "3",
      label: "KANDI II",
      value: "21",
    },
    {
      COMMUNES: "3",
      label: "KANDI III",
      value: "22",
    },
    {
      COMMUNES: "3",
      label: "KASSAKOU",
      value: "23",
    },
    {
      COMMUNES: "3",
      label: "SAAH",
      value: "24",
    },
    {
      COMMUNES: "3",
      label: "SAM",
      value: "25",
    },
    {
      COMMUNES: "3",
      label: "SONSORO",
      value: "26",
    },
    {
      COMMUNES: "4",
      label: "BIRNI LAFIA",
      value: "27",
    },
    {
      COMMUNES: "4",
      label: "BOGO-BOGO",
      value: "28",
    },
    {
      COMMUNES: "4",
      label: "KARIMAMA",
      value: "29",
    },
    {
      COMMUNES: "4",
      label: "KOMPA",
      value: "30",
    },
    {
      COMMUNES: "4",
      label: " MONSEY",
      value: "31",
    },
    {
      COMMUNES: "5",
      label: "GAROU",
      value: "32",
    },
    {
      COMMUNES: "5",
      label: "GUÉNÉ2",
      value: "33",
    },
    {
      COMMUNES: "5",
      label: "MALANVILLE",
      value: "34",
    },
    {
      COMMUNES: "5",
      label: "MADÉCALI",
      value: "35",
    },
    {
      COMMUNES: "5",
      label: "TOUMBOUTOU",
      value: "36",
    },
    {
      COMMUNES: "6",
      label: "LIBANTÈ",
      value: "37",
    },
    {
      COMMUNES: "6",
      label: "LIBOUSSOU",
      value: "38",
    },
    {
      COMMUNES: "6",
      label: "LOUGOU",
      value: "39",
    },
    {
      COMMUNES: "6",
      label: "SEGBANA",
      value: "40",
    },
    {
      COMMUNES: "6",
      label: "SOKOTINDJI",
      value: "41",
    },
    {
      COMMUNES: "7",
      label: "BOUKOUMBÉ",
      value: "42",
    },
    {
      COMMUNES: "7",
      label: "DIPOLI",
      value: "43",
    },
    {
      COMMUNES: "7",
      label: "KORONTIÈRE",
      value: "44",
    },
    {
      COMMUNES: "7",
      label: "KOSSOUCOINGOU",
      value: "45",
    },
    {
      COMMUNES: "7",
      label: "MANTA",
      value: "46",
    },
    {
      COMMUNES: "7",
      label: "NATTA",
      value: "47",
    },
    {
      COMMUNES: "7",
      label: "TABOTA",
      value: "48",
    },
    {
      COMMUNES: "8",
      label: "COBLY",
      value: "49",
    },
    {
      COMMUNES: "8",
      label: "DATORI",
      value: "50",
    },
    {
      COMMUNES: "8",
      label: "KOUNTORI",
      value: "51",
    },
    {
      COMMUNES: "8",
      label: "TAPOGA",
      value: "52",
    },
    {
      COMMUNES: "9",
      label: "BRIGNAMARO",
      value: "53",
    },
    {
      COMMUNES: "9",
      label: "FIROU",
      value: "54",
    },
    {
      COMMUNES: "9",
      label: "KÉROU",
      value: "55",
    },
    {
      COMMUNES: "9",
      label: "KOABAGOU",
      value: "56",
    },
    {
      COMMUNES: "10",
      label: "BIRNI",
      value: "57",
    },
    {
      COMMUNES: "10",
      label: "CHABI-COUMA",
      value: "58",
    },
    {
      COMMUNES: "10",
      label: "FÔ-TANCÉ",
      value: "59",
    },
    {
      COMMUNES: "10",
      label: "GUILMARO",
      value: "60",
    },
    {
      COMMUNES: "10",
      label: "KOUANDÉ",
      value: "61",
    },
    {
      COMMUNES: "10",
      label: "OROUKAYO",
      value: "62",
    },
    {
      COMMUNES: "11",
      label: "DASSARI",
      value: "63",
    },
    {
      COMMUNES: "11",
      label: "GOUANDÉ",
      value: "64",
    },
    {
      COMMUNES: "11",
      label: "MATÉRI",
      value: "65",
    },
    {
      COMMUNES: "11",
      label: "NODI",
      value: "66",
    },
    {
      COMMUNES: "11",
      label: "TANTÉGA",
      value: "67",
    },
    {
      COMMUNES: "11",
      label: "TCHIANHOUN-COSSI",
      value: "68",
    },
    {
      COMMUNES: "12",
      label: "KOTOPOUNGA",
      value: "69",
    },
    {
      COMMUNES: "12",
      label: "KOUABA",
      value: "70",
    },
    {
      COMMUNES: "12",
      label: "KOUNDATA",
      value: "71",
    },
    {
      COMMUNES: "12",
      label: "NATITINGOU I",
      value: "72",
    },
    {
      COMMUNES: "12",
      label: "NATITINGOU II",
      value: "73",
    },
    {
      COMMUNES: "12",
      label: "NATITINGOU III",
      value: "74",
    },
    {
      COMMUNES: "12",
      label: "NATITINGOU IV",
      value: "75",
    },
    {
      COMMUNES: "12",
      label: "PERMA",
      value: "76",
    },
    {
      COMMUNES: "12",
      label: "TCHOUMI-TCHOUMI",
      value: "77",
    },
    {
      COMMUNES: "13",
      label: "GNÉMASSON",
      value: "78",
    },
    {
      COMMUNES: "13",
      label: "PÉHUNCO",
      value: "79",
    },
    {
      COMMUNES: "13",
      label: "TOBRÉ",
      value: "80",
    },
    {
      COMMUNES: "14",
      label: "COTIAKOU",
      value: "81",
    },
    {
      COMMUNES: "14",
      label: "N'DAHONTA",
      value: "82",
    },
    {
      COMMUNES: "14",
      label: "TAIAKOU",
      value: "83",
    },
    {
      COMMUNES: "14",
      label: "TANGUIÉTA",
      value: "84",
    },
    {
      COMMUNES: "14",
      label: "TANONGOU",
      value: "85",
    },
    {
      COMMUNES: "15",
      label: "KOUARFA",
      value: "86",
    },
    {
      COMMUNES: "15",
      label: "TAMPÉGRÉ",
      value: "87",
    },
    {
      COMMUNES: "15",
      label: "TOUCOUNTOUNA",
      value: "88",
    },
    {
      COMMUNES: "16",
      label: "ABOMEY-CALAVI",
      value: "89",
    },
    {
      COMMUNES: "16",
      label: "AKASSATO",
      value: "90",
    },
    {
      COMMUNES: "16",
      label: "GODOMEY",
      value: "91",
    },
    {
      COMMUNES: "16",
      label: "GLO-DJIGBÉ",
      value: "92",
    },
    {
      COMMUNES: "16",
      label: "HÊVIÉ",
      value: "93",
    },
    {
      COMMUNES: "16",
      label: "KPANROUN",
      value: "94",
    },
    {
      COMMUNES: "16",
      label: "OUÈDO",
      value: "95",
    },
    {
      COMMUNES: "16",
      label: "TOGBA",
      value: "96",
    },
    {
      COMMUNES: "16",
      label: "ZINVIÉ",
      value: "97",
    },
    {
      COMMUNES: "17",
      label: "AGBANOU",
      value: "98",
    },
    {
      COMMUNES: "17",
      label: "AHOUANNONZOUN",
      value: "99",
    },
    {
      COMMUNES: "17",
      label: "ALLADA",
      value: "100",
    },
    {
      COMMUNES: "17",
      label: "ATTOGON",
      value: "101",
    },
    {
      COMMUNES: "17",
      label: "AVAKPA",
      value: "102",
    },
    {
      COMMUNES: "17",
      label: "AYOU",
      value: "103",
    },
    {
      COMMUNES: "17",
      label: "HINVI",
      value: "104",
    },
    {
      COMMUNES: "17",
      label: "LISSÈGAZOUN",
      value: "105",
    },
    {
      COMMUNES: "17",
      label: "LON-AGONMEY",
      value: "106",
    },
    {
      COMMUNES: "17",
      label: "SEKOU",
      value: "107",
    },
    {
      COMMUNES: "17",
      label: "TOGOUDO",
      value: "108",
    },
    {
      COMMUNES: "17",
      label: "TOKPA-AVAGOUDO",
      value: "109",
    },
    {
      COMMUNES: "18",
      label: "AGANMALOMÈ",
      value: "110",
    },
    {
      COMMUNES: "18",
      label: "AGBANTO",
      value: "111",
    },
    {
      COMMUNES: "18",
      label: "AGONKANMÈ",
      value: "112",
    },
    {
      COMMUNES: "18",
      label: "DÉDOMÈ",
      value: "113",
    },
    {
      COMMUNES: "18",
      label: "DÉKANMÈ",
      value: "114",
    },
    {
      COMMUNES: "18",
      label: "KPOMASSÈ",
      value: "115",
    },
    {
      COMMUNES: "18",
      label: "SÈGBÈYA",
      value: "116",
    },
    {
      COMMUNES: "18",
      label: "SÈGBOHOUÈ",
      value: "117",
    },
    {
      COMMUNES: "18",
      label: "TOKPA-DOMÈ",
      value: "118",
    },
    {
      COMMUNES: "19",
      label: "AVLÉKÉTÉ",
      value: "119",
    },
    {
      COMMUNES: "19",
      label: "DJÈGBADJI",
      value: "120",
    },
    {
      COMMUNES: "19",
      label: "GAKPÉ",
      value: "121",
    },
    {
      COMMUNES: "19",
      label: "HOUAKPÈ-DAHO",
      value: "122",
    },
    {
      COMMUNES: "19",
      label: "OUIDAH I",
      value: "123",
    },
    {
      COMMUNES: "19",
      label: "OUIDAH II",
      value: "124",
    },
    {
      COMMUNES: "19",
      label: "OUIDAH III",
      value: "125",
    },
    {
      COMMUNES: "19",
      label: "OUIDAH IV",
      value: "126",
    },
    {
      COMMUNES: "19",
      label: "PAHOU",
      value: "127",
    },
    {
      COMMUNES: "19",
      label: "SAVI",
      value: "128",
    },
    {
      COMMUNES: "20",
      label: "AHOMEY-LOKPO",
      value: "129",
    },
    {
      COMMUNES: "20",
      label: "DÉKANMEY",
      value: "130",
    },
    {
      COMMUNES: "20",
      label: "GANVIÉ I",
      value: "131",
    },
    {
      COMMUNES: "20",
      label: "GANVIÉ II",
      value: "132",
    },
    {
      COMMUNES: "20",
      label: "HOUÉDO-AGUÉKON",
      value: "133",
    },
    {
      COMMUNES: "20",
      label: "SÔ-AVA",
      value: "134",
    },
    {
      COMMUNES: "20",
      label: "VEKKY",
      value: "135",
    },
    {
      COMMUNES: "21",
      label: "AGUÉ",
      value: "136",
    },
    {
      COMMUNES: "21",
      label: "COLLI-AGBAMÈ",
      value: "137",
    },
    {
      COMMUNES: "21",
      label: "COUSSI",
      value: "138",
    },
    {
      COMMUNES: "21",
      label: "DAMÈ",
      value: "139",
    },
    {
      COMMUNES: "21",
      label: "DJANGLANMÈ",
      value: "140",
    },
    {
      COMMUNES: "21",
      label: "HOUÈGBO",
      value: "141",
    },
    {
      COMMUNES: "21",
      label: "KPOMÈ",
      value: "142",
    },
    {
      COMMUNES: "21",
      label: "SÈ",
      value: "143",
    },
    {
      COMMUNES: "21",
      label: "SÈHOUÈ",
      value: "144",
    },
    {
      COMMUNES: "21",
      label: "TOFFO-AGUÉ",
      value: "145",
    },
    {
      COMMUNES: "22",
      label: "AVAMÈ",
      value: "146",
    },
    {
      COMMUNES: "22",
      label: "AZOHOUÈ-ALIHO",
      value: "147",
    },
    {
      COMMUNES: "22",
      label: "AZOHOUÈ-CADA",
      value: "148",
    },
    {
      COMMUNES: "22",
      label: "TORI-BOSSITO",
      value: "149",
    },
    {
      COMMUNES: "22",
      label: "TORI-CADA",
      value: "150",
    },
    {
      COMMUNES: "22",
      label: "TORI-GARE ",
      value: "151",
    },
    {
      COMMUNES: "22",
      label: "TORI AÏDOHOUE",
      value: "152",
    },
    {
      COMMUNES: "22",
      label: "TORI ACADJAMÈ",
      value: "153",
    },
    {
      COMMUNES: "23",
      label: "ADJAN",
      value: "154",
    },
    {
      COMMUNES: "23",
      label: "DAWÈ",
      value: "155",
    },
    {
      COMMUNES: "23",
      label: "DJIGBÉ",
      value: "156",
    },
    {
      COMMUNES: "23",
      label: "DODJI-BATA",
      value: "157",
    },
    {
      COMMUNES: "23",
      label: "HÈKANMÉ",
      value: "158",
    },
    {
      COMMUNES: "23",
      label: "KOUNDOKPOÉ",
      value: "159",
    },
    {
      COMMUNES: "23",
      label: "SÈDJÈ-DÉNOU",
      value: "160",
    },
    {
      COMMUNES: "23",
      label: "SÈDJÈ-HOUÉGOUDO",
      value: "161",
    },
    {
      COMMUNES: "23",
      label: "TANGBO-DJEVIÉ",
      value: "162",
    },
    {
      COMMUNES: "23",
      label: "YOKPO",
      value: "163",
    },
    {
      COMMUNES: "23",
      label: " ZÈ",
      value: "164",
    },
    {
      COMMUNES: "24",
      label: "BEMBÉRÉKÉ",
      value: "165",
    },
    {
      COMMUNES: "24",
      label: "BÉROUBOUAY",
      value: "166",
    },
    {
      COMMUNES: "24",
      label: "BOUANRI",
      value: "167",
    },
    {
      COMMUNES: "24",
      label: "GAMIA",
      value: "168",
    },
    {
      COMMUNES: "24",
      label: "INA",
      value: "169",
    },
    {
      COMMUNES: "25",
      label: "BASSO",
      value: "170",
    },
    {
      COMMUNES: "25",
      label: "BOUKA",
      value: "171",
    },
    {
      COMMUNES: "25",
      label: "DÉRASSI",
      value: "172",
    },
    {
      COMMUNES: "25",
      label: "DUNKASSA",
      value: "173",
    },
    {
      COMMUNES: "25",
      label: "KALALÉ",
      value: "174",
    },
    {
      COMMUNES: "25",
      label: "PÉONGA",
      value: "175",
    },
    {
      COMMUNES: "26",
      label: "BORI",
      value: "176",
    },
    {
      COMMUNES: "26",
      label: "GBÉGOUROU",
      value: "177",
    },
    {
      COMMUNES: "26",
      label: "N'DALI",
      value: "178",
    },
    {
      COMMUNES: "26",
      label: "OUÉNOU",
      value: "179",
    },
    {
      COMMUNES: "26",
      label: "SIRAROU",
      value: "180",
    },
    {
      COMMUNES: "27",
      label: "BIRO",
      value: "181",
    },
    {
      COMMUNES: "27",
      label: "GNONKOURAKALI",
      value: "182",
    },
    {
      COMMUNES: "27",
      label: "NIKKI",
      value: "183",
    },
    {
      COMMUNES: "27",
      label: "OUÉNOU",
      value: "184",
    },
    {
      COMMUNES: "27",
      label: "SÉRÉKALÉ",
      value: "185",
    },
    {
      COMMUNES: "27",
      label: "SUYA",
      value: "186",
    },
    {
      COMMUNES: "27",
      label: "TASSO",
      value: "187",
    },
    {
      COMMUNES: "28",
      label: "1ER ARRONDISSEMENT DE PARAKOU",
      value: "188",
    },
    {
      COMMUNES: "28",
      label: "2E ARRONDISSEMENT DE PARAKOU",
      value: "189",
    },
    {
      COMMUNES: "28",
      label: "3E ARRONDISSEMENT DE PARAKOU",
      value: "190",
    },
    {
      COMMUNES: "29",
      label: "GNINSY",
      value: "191",
    },
    {
      COMMUNES: "29",
      label: "GUINAGOUROU",
      value: "192",
    },
    {
      COMMUNES: "29",
      label: "KPANÉ",
      value: "193",
    },
    {
      COMMUNES: "29",
      label: "PÉBIÉ",
      value: "194",
    },
    {
      COMMUNES: "29",
      label: "PÈRÈRÈ",
      value: "195",
    },
    {
      COMMUNES: "29",
      label: "SONTOU",
      value: "196",
    },
    {
      COMMUNES: "30",
      label: "FÔ-BOURÈ",
      value: "197",
    },
    {
      COMMUNES: "30",
      label: "SÈKÈRÈ",
      value: "198",
    },
    {
      COMMUNES: "30",
      label: "SIKKI",
      value: "199",
    },
    {
      COMMUNES: "30",
      label: "SINENDÉ",
      value: "200",
    },
    {
      COMMUNES: "31",
      label: "ALAFIAROU",
      value: "201",
    },
    {
      COMMUNES: "31",
      label: " BÉTÉROU",
      value: "202",
    },
    {
      COMMUNES: "31",
      label: "GORO",
      value: "203",
    },
    {
      COMMUNES: "31",
      label: "KIKA",
      value: "204",
    },
    {
      COMMUNES: "31",
      label: "DANSON",
      value: "205",
    },
    {
      COMMUNES: "31",
      label: "TCHAOUROU",
      value: "206",
    },
    {
      COMMUNES: "31",
      label: "TCHATCHOU",
      value: "207",
    },
    {
      COMMUNES: "32",
      label: "AGOUA",
      value: "208",
    },
    {
      COMMUNES: "32",
      label: "AKPASSI",
      value: "209",
    },
    {
      COMMUNES: "32",
      label: "ATOKOLIBE",
      value: "210",
    },
    {
      COMMUNES: "32",
      label: "BANTE ",
      value: "211",
    },
    {
      COMMUNES: "32",
      label: "BOBE",
      value: "212",
    },
    {
      COMMUNES: "32",
      label: "GOUKA",
      value: "213",
    },
    {
      COMMUNES: "32",
      label: "LOUGBA",
      value: "214",
    },
    {
      COMMUNES: "32",
      label: "KOKO",
      value: "215",
    },
    {
      COMMUNES: "32",
      label: "PIRA",
      value: "216",
    },
    {
      COMMUNES: "33",
      label: "AKOFFODJOULE",
      value: "217",
    },
    {
      COMMUNES: "33",
      label: "DASSA I",
      value: "218",
    },
    {
      COMMUNES: "33",
      label: "DASSA II",
      value: "219",
    },
    {
      COMMUNES: "33",
      label: "GBAFFO",
      value: "220",
    },
    {
      COMMUNES: "33",
      label: "LEMA",
      value: "221",
    },
    {
      COMMUNES: "33",
      label: "KERE ",
      value: "222",
    },
    {
      COMMUNES: "33",
      label: "KPINGNI",
      value: "223",
    },
    {
      COMMUNES: "33",
      label: "PAOUINGNAN ",
      value: "224",
    },
    {
      COMMUNES: "33",
      label: "SOCLOGBO ",
      value: "225",
    },
    {
      COMMUNES: "33",
      label: "TRE ",
      value: "226",
    },
    {
      COMMUNES: "34",
      label: "AKLAMPA",
      value: "227",
    },
    {
      COMMUNES: "34",
      label: "ASSANTE",
      value: "228",
    },
    {
      COMMUNES: "34",
      label: "GLAZOUE ",
      value: "229",
    },
    {
      COMMUNES: "34",
      label: "GOME ",
      value: "230",
    },
    {
      COMMUNES: "34",
      label: "KPAKPAZA ",
      value: "231",
    },
    {
      COMMUNES: "34",
      label: "MAGOUMI ",
      value: "232",
    },
    {
      COMMUNES: "34",
      label: "OUEDEME ",
      value: "233",
    },
    {
      COMMUNES: "34",
      label: "SOKPONTA ",
      value: "234",
    },
    {
      COMMUNES: "34",
      label: "THIO ",
      value: "235",
    },
    {
      COMMUNES: "34",
      label: "ZAFFE ",
      value: "236",
    },
    {
      COMMUNES: "35",
      label: "CHALLA-OGOÏ ",
      value: "237",
    },
    {
      COMMUNES: "35",
      label: "DJEGBE ",
      value: "238",
    },
    {
      COMMUNES: "35",
      label: "GBANLIN ",
      value: "239",
    },
    {
      COMMUNES: "35",
      label: "IKEMON ",
      value: "240",
    },
    {
      COMMUNES: "35",
      label: "KILIBO ",
      value: "241",
    },
    {
      COMMUNES: "35",
      label: "LAMINOU ",
      value: "242",
    },
    {
      COMMUNES: "35",
      label: "ODOUGBA",
      value: "243",
    },
    {
      COMMUNES: "35",
      label: "OUESSE ",
      value: "244",
    },
    {
      COMMUNES: "35",
      label: "TOUI ",
      value: "245",
    },
    {
      COMMUNES: "36",
      label: "SAVALOU-AGA",
      value: "246",
    },
    {
      COMMUNES: "36",
      label: "SAVALOU-AGBADO",
      value: "247",
    },
    {
      COMMUNES: "36",
      label: "SAVALOU-ATTAKE",
      value: "248",
    },
    {
      COMMUNES: "36",
      label: "DJALLOUKOU",
      value: "249",
    },
    {
      COMMUNES: "36",
      label: "DOUME ",
      value: "250",
    },
    {
      COMMUNES: "36",
      label: "GOBADA ",
      value: "251",
    },
    {
      COMMUNES: "36",
      label: "KPATABA ",
      value: "252",
    },
    {
      COMMUNES: "36",
      label: "LAHOTAN ",
      value: "253",
    },
    {
      COMMUNES: "36",
      label: "LEMA ",
      value: "254",
    },
    {
      COMMUNES: "36",
      label: "LOGOZOHE ",
      value: "255",
    },
    {
      COMMUNES: "36",
      label: "MONKPA ",
      value: "256",
    },
    {
      COMMUNES: "36",
      label: "OUESSE ",
      value: "257",
    },
    {
      COMMUNES: "36",
      label: "OTTOLA",
      value: "258",
    },
    {
      COMMUNES: "36",
      label: "TCHETTI ",
      value: "259",
    },
    {
      COMMUNES: "37",
      label: "ADIDO",
      value: "260",
    },
    {
      COMMUNES: "37",
      label: "BESSE ",
      value: "261",
    },
    {
      COMMUNES: "37",
      label: "BONI ",
      value: "262",
    },
    {
      COMMUNES: "37",
      label: "KABOUA ",
      value: "263",
    },
    {
      COMMUNES: "37",
      label: "OKPARA",
      value: "264",
    },
    {
      COMMUNES: "37",
      label: "PLATEAU ",
      value: "265",
    },
    {
      COMMUNES: "37",
      label: "SAKIN ",
      value: "266",
    },
    {
      COMMUNES: "37",
      label: "OFFE ",
      value: "267",
    },
    {
      COMMUNES: "38",
      label: "APLAHOUE",
      value: "268",
    },
    {
      COMMUNES: "38",
      label: "ATOMEY",
      value: "269",
    },
    {
      COMMUNES: "38",
      label: "AZOVE",
      value: "270",
    },
    {
      COMMUNES: "38",
      label: "DEKPO",
      value: "271",
    },
    {
      COMMUNES: "38",
      label: "GODOHOU",
      value: "272",
    },
    {
      COMMUNES: "38",
      label: "KISSAMEY",
      value: "273",
    },
    {
      COMMUNES: "38",
      label: "LONKLY",
      value: "274",
    },
    {
      COMMUNES: "39",
      label: "ADJINTIMEY",
      value: "275",
    },
    {
      COMMUNES: "39",
      label: "BETOUMEY",
      value: "276",
    },
    {
      COMMUNES: "39",
      label: "DJAKOTOMEY I",
      value: "277",
    },
    {
      COMMUNES: "39",
      label: "DJAKOTOMEY II",
      value: "278",
    },
    {
      COMMUNES: "39",
      label: "GOHOMEY",
      value: "279",
    },
    {
      COMMUNES: "39",
      label: "HOUEGAMEY",
      value: "280",
    },
    {
      COMMUNES: "39",
      label: "KINKINHOUE",
      value: "281",
    },
    {
      COMMUNES: "39",
      label: "KOKOHOUE",
      value: "282",
    },
    {
      COMMUNES: "39",
      label: "KPOBA",
      value: "283",
    },
    {
      COMMUNES: "39",
      label: "SOKOUHOUE",
      value: "284",
    },
    {
      COMMUNES: "40",
      label: "AYOMI",
      value: "285",
    },
    {
      COMMUNES: "40",
      label: "DEVE",
      value: "286",
    },
    {
      COMMUNES: "40",
      label: "HONTON",
      value: "287",
    },
    {
      COMMUNES: "40",
      label: "LOKOGOHOUE",
      value: "288",
    },
    {
      COMMUNES: "40",
      label: "MADJRE",
      value: "289",
    },
    {
      COMMUNES: "40",
      label: "TOTA",
      value: "290",
    },
    {
      COMMUNES: "40",
      label: "TOTCHANGNI CENTRE",
      value: "291",
    },
    {
      COMMUNES: "41",
      label: "ADJANHONME",
      value: "292",
    },
    {
      COMMUNES: "41",
      label: "AHOGBEYA",
      value: "293",
    },
    {
      COMMUNES: "41",
      label: "AYAHOHOUE",
      value: "294",
    },
    {
      COMMUNES: "41",
      label: "DJOTTO",
      value: "295",
    },
    {
      COMMUNES: "41",
      label: "HONDJIN",
      value: "296",
    },
    {
      COMMUNES: "41",
      label: "KLOUEKANME",
      value: "297",
    },
    {
      COMMUNES: "41",
      label: "LANTA",
      value: "298",
    },
    {
      COMMUNES: "41",
      label: "TCHIKPE",
      value: "299",
    },
    {
      COMMUNES: "42",
      label: "ADOUKANDJI",
      value: "300",
    },
    {
      COMMUNES: "42",
      label: "AHODJINNAKO",
      value: "301",
    },
    {
      COMMUNES: "42",
      label: "AHOMADEGBE",
      value: "302",
    },
    {
      COMMUNES: "42",
      label: "BANIGBE",
      value: "303",
    },
    {
      COMMUNES: "42",
      label: "GNIZOUNME",
      value: "304",
    },
    {
      COMMUNES: "42",
      label: "HLASSAME",
      value: "305",
    },
    {
      COMMUNES: "42",
      label: "LALO",
      value: "306",
    },
    {
      COMMUNES: "42",
      label: "LOKOGBA",
      value: "307",
    },
    {
      COMMUNES: "42",
      label: "TCHITO",
      value: "308",
    },
    {
      COMMUNES: "42",
      label: "TOHOU",
      value: "309",
    },
    {
      COMMUNES: "42",
      label: "ZALLI",
      value: "310",
    },
    {
      COMMUNES: "43",
      label: "ADJIDO",
      value: "311",
    },
    {
      COMMUNES: "43",
      label: "AVEDJIN",
      value: "312",
    },
    {
      COMMUNES: "43",
      label: "DOKO",
      value: "313",
    },
    {
      COMMUNES: "43",
      label: "HOUEDOGLI",
      value: "314",
    },
    {
      COMMUNES: "43",
      label: "MISSINKO",
      value: "315",
    },
    {
      COMMUNES: "43",
      label: "TANNOU GOLA",
      value: "316",
    },
    {
      COMMUNES: "43",
      label: "TOVIKLIN",
      value: "317",
    },
    {
      COMMUNES: "44",
      label: "ALEDJO",
      value: "318",
    },
    {
      COMMUNES: "44",
      label: "BASSILA",
      value: "319",
    },
    {
      COMMUNES: "44",
      label: "MANIGRI",
      value: "320",
    },
    {
      COMMUNES: "44",
      label: "PENESSOULOU",
      value: "321",
    },
    {
      COMMUNES: "45",
      label: "ANANDANA",
      value: "322",
    },
    {
      COMMUNES: "45",
      label: "COPARGO",
      value: "323",
    },
    {
      COMMUNES: "45",
      label: "PABEGOU",
      value: "324",
    },
    {
      COMMUNES: "45",
      label: "SINGRE",
      value: "325",
    },
    {
      COMMUNES: "46",
      label: "BAREI",
      value: "326",
    },
    {
      COMMUNES: "46",
      label: "BARIENOU",
      value: "327",
    },
    {
      COMMUNES: "46",
      label: "BELLEFOUNGOU",
      value: "328",
    },
    {
      COMMUNES: "46",
      label: "BOUGOU",
      value: "329",
    },
    {
      COMMUNES: "46",
      label: "DJOUGOU I",
      value: "330",
    },
    {
      COMMUNES: "46",
      label: "DJOUGOU II",
      value: "331",
    },
    {
      COMMUNES: "46",
      label: "DJOUGOU III",
      value: "332",
    },
    {
      COMMUNES: "46",
      label: "KOLOKONDE",
      value: "333",
    },
    {
      COMMUNES: "46",
      label: "ONKLOU",
      value: "334",
    },
    {
      COMMUNES: "46",
      label: "PATARGO",
      value: "335",
    },
    {
      COMMUNES: "46",
      label: "PELEBINA",
      value: "336",
    },
    {
      COMMUNES: "46",
      label: "SEROU",
      value: "337",
    },
    {
      COMMUNES: "47",
      label: "BAJOUDE",
      value: "338",
    },
    {
      COMMUNES: "47",
      label: "KOMDE",
      value: "339",
    },
    {
      COMMUNES: "47",
      label: "OUAKE",
      value: "340",
    },
    {
      COMMUNES: "47",
      label: "SEMERE I",
      value: "341",
    },
    {
      COMMUNES: "47",
      label: "SEMERE II",
      value: "342",
    },
    {
      COMMUNES: "47",
      label: "TCHALINGA",
      value: "343",
    },
    {
      COMMUNES: "48",
      label: "1ER ARRONDISSEMENT",
      value: "344",
    },
    {
      COMMUNES: "48",
      label: "2ÈME ARRONDISSEMENT",
      value: "345",
    },
    {
      COMMUNES: "48",
      label: "3ÈME  ARRONDISSEMENT",
      value: "346",
    },
    {
      COMMUNES: "48",
      label: "4ÈME  ARRONDISSEMENT",
      value: "347",
    },
    {
      COMMUNES: "48",
      label: "5ÈME ARRONDISSEMENT ",
      value: "348",
    },
    {
      COMMUNES: "48",
      label: "6ÈME ARRONDISSÈMENT",
      value: "349",
    },
    {
      COMMUNES: "48",
      label: "7ÈME ARRONDISSEMENT",
      value: "350",
    },
    {
      COMMUNES: "48",
      label: "8ÈME ARRONDISSEMENT",
      value: "351",
    },
    {
      COMMUNES: "48",
      label: "9ÈME ARRONDISSEMENT",
      value: "352",
    },
    {
      COMMUNES: "48",
      label: "10ÈME ARRONDISSEMENT",
      value: "353",
    },
    {
      COMMUNES: "48",
      label: "11ÈME ARRONDISSEMENT",
      value: "354",
    },
    {
      COMMUNES: "48",
      label: "12ÈME ARRONDISSEMENT",
      value: "355",
    },
    {
      COMMUNES: "48",
      label: "13ÈME ARRONDISSEMENT",
      value: "356",
    },
    {
      COMMUNES: "49",
      label: "ADOHOUN ",
      value: "357",
    },
    {
      COMMUNES: "49",
      label: "ATCHANNOU",
      value: "358",
    },
    {
      COMMUNES: "49",
      label: "ATHIÉMÉ",
      value: "359",
    },
    {
      COMMUNES: "49",
      label: "DÉDÉKPOÈ",
      value: "360",
    },
    {
      COMMUNES: "49",
      label: "KPINNOU",
      value: "361",
    },
    {
      COMMUNES: "50",
      label: "AGBODJI ",
      value: "362",
    },
    {
      COMMUNES: "50",
      label: "BADAZOUIN",
      value: "363",
    },
    {
      COMMUNES: "50",
      label: "BOPA",
      value: "364",
    },
    {
      COMMUNES: "50",
      label: "GBAKPODJI",
      value: "365",
    },
    {
      COMMUNES: "50",
      label: "LOBOGO",
      value: "366",
    },
    {
      COMMUNES: "50",
      label: "POSSOTOMÈ",
      value: "367",
    },
    {
      COMMUNES: "50",
      label: "YÈGODOÉ",
      value: "368",
    },
    {
      COMMUNES: "51",
      label: "AGATOGBO",
      value: "369",
    },
    {
      COMMUNES: "51",
      label: "AKODÉHA",
      value: "370",
    },
    {
      COMMUNES: "51",
      label: "COMÉ",
      value: "371",
    },
    {
      COMMUNES: "51",
      label: "OUMAKO",
      value: "372",
    },
    {
      COMMUNES: "51",
      label: "OUÈDÈMÈ-PÉDAH",
      value: "373",
    },
    {
      COMMUNES: "52",
      label: "ADJAHA",
      value: "374",
    },
    {
      COMMUNES: "52",
      label: "AGOUÉ",
      value: "375",
    },
    {
      COMMUNES: "52",
      label: "AVLOH",
      value: "376",
    },
    {
      COMMUNES: "52",
      label: "DJANGLANMEY",
      value: "377",
    },
    {
      COMMUNES: "52",
      label: "GBÉHOUÉ",
      value: "378",
    },
    {
      COMMUNES: "52",
      label: "SAZOUÉ",
      value: "379",
    },
    {
      COMMUNES: "52",
      label: "GRAND-POPO",
      value: "380",
    },
    {
      COMMUNES: "53",
      label: "DAHÉ ",
      value: "381",
    },
    {
      COMMUNES: "53",
      label: "DOUTOU",
      value: "382",
    },
    {
      COMMUNES: "53",
      label: "HONHOUÉ",
      value: "383",
    },
    {
      COMMUNES: "53",
      label: "HOUÉYOGBÉ",
      value: "384",
    },
    {
      COMMUNES: "53",
      label: "SÈ",
      value: "385",
    },
    {
      COMMUNES: "53",
      label: "ZOUNGBONOU",
      value: "386",
    },
    {
      COMMUNES: "54",
      label: "LOKOSSA",
      value: "387",
    },
    {
      COMMUNES: "54",
      label: "OUÈDÈMÈ",
      value: "388",
    },
    {
      COMMUNES: "54",
      label: "KOUDO",
      value: "389",
    },
    {
      COMMUNES: "54",
      label: "AGAMÈ",
      value: "390",
    },
    {
      COMMUNES: "54",
      label: "HOUIN",
      value: "391",
    },
    {
      COMMUNES: "55",
      label: "ADJARRA I ",
      value: "392",
    },
    {
      COMMUNES: "55",
      label: "ADJARRA II",
      value: "393",
    },
    {
      COMMUNES: "55",
      label: "HONVIÉ",
      value: "394",
    },
    {
      COMMUNES: "55",
      label: "MALANHOUI",
      value: "395",
    },
    {
      COMMUNES: "55",
      label: "AGLOGBÈ",
      value: "396",
    },
    {
      COMMUNES: "55",
      label: "MÉDÉDJONOU",
      value: "397",
    },
    {
      COMMUNES: "56",
      label: "ADJOHOUN",
      value: "398",
    },
    {
      COMMUNES: "56",
      label: "AKPADANOU",
      value: "399",
    },
    {
      COMMUNES: "56",
      label: "AWONOU",
      value: "400",
    },
    {
      COMMUNES: "56",
      label: "AZOWLISSÈ",
      value: "401",
    },
    {
      COMMUNES: "56",
      label: "DÈMÈ",
      value: "402",
    },
    {
      COMMUNES: "56",
      label: "GANGBAN",
      value: "403",
    },
    {
      COMMUNES: "56",
      label: "KODÈ",
      value: "404",
    },
    {
      COMMUNES: "56",
      label: "TOGBOTA",
      value: "405",
    },
    {
      COMMUNES: "57",
      label: "AVAGBODJI",
      value: "406",
    },
    {
      COMMUNES: "57",
      label: "HOUÉDOMÈ",
      value: "407",
    },
    {
      COMMUNES: "57",
      label: "ZOUNGAMÈ",
      value: "408",
    },
    {
      COMMUNES: "58",
      label: "AKPRO-MISSÉRÉTÉ",
      value: "409",
    },
    {
      COMMUNES: "58",
      label: "GOMÈ-SOTA",
      value: "410",
    },
    {
      COMMUNES: "58",
      label: "KATAGON",
      value: "411",
    },
    {
      COMMUNES: "58",
      label: "VAKON",
      value: "412",
    },
    {
      COMMUNES: "58",
      label: "ZODOGBOMEY",
      value: "413",
    },
    {
      COMMUNES: "59",
      label: "ATCHOUKPA",
      value: "414",
    },
    {
      COMMUNES: "59",
      label: "AVRANKOU",
      value: "415",
    },
    {
      COMMUNES: "59",
      label: "DJOMON",
      value: "416",
    },
    {
      COMMUNES: "59",
      label: "GBOZOUNMÈ",
      value: "417",
    },
    {
      COMMUNES: "59",
      label: "KOUTY",
      value: "418",
    },
    {
      COMMUNES: "59",
      label: "OUANHO",
      value: "419",
    },
    {
      COMMUNES: "59",
      label: "SADO",
      value: "420",
    },
    {
      COMMUNES: "60",
      label: "AFFAMÈ",
      value: "421",
    },
    {
      COMMUNES: "60",
      label: "ATCHONSA",
      value: "422",
    },
    {
      COMMUNES: "60",
      label: "BONOU",
      value: "423",
    },
    {
      COMMUNES: "60",
      label: "DAMÈ-WOGON",
      value: "424",
    },
    {
      COMMUNES: "60",
      label: "HOUINVIGUÈ",
      value: "425",
    },
    {
      COMMUNES: "61",
      label: "DANGBO",
      value: "426",
    },
    {
      COMMUNES: "61",
      label: "DÈKIN",
      value: "427",
    },
    {
      COMMUNES: "61",
      label: "GBÉKO",
      value: "428",
    },
    {
      COMMUNES: "61",
      label: "HOUÉDOMEY",
      value: "429",
    },
    {
      COMMUNES: "61",
      label: "HOZIN",
      value: "430",
    },
    {
      COMMUNES: "61",
      label: "KÉSSOUNOU",
      value: "431",
    },
    {
      COMMUNES: "61",
      label: "ZOUNGUÈ",
      value: "432",
    },
    {
      COMMUNES: "62",
      label: "1ER ARRONDISSEMENT",
      value: "433",
    },
    {
      COMMUNES: "62",
      label: "2E ARRONDISSEMENT",
      value: "434",
    },
    {
      COMMUNES: "62",
      label: "3E ARRONDISSEMENT",
      value: "435",
    },
    {
      COMMUNES: "62",
      label: "4E ARRONDISSEMENT",
      value: "436",
    },
    {
      COMMUNES: "62",
      label: "5E ARRONDISSEMENT",
      value: "437",
    },
    {
      COMMUNES: "63",
      label: "AGBLANGANDAN",
      value: "438",
    },
    {
      COMMUNES: "63",
      label: "AHOLOUYÈMÈ",
      value: "439",
    },
    {
      COMMUNES: "63",
      label: "DJÈRÈGBÈ",
      value: "440",
    },
    {
      COMMUNES: "63",
      label: "EKPÈ",
      value: "441",
    },
    {
      COMMUNES: "63",
      label: "SÈMÈ-KPODJI",
      value: "442",
    },
    {
      COMMUNES: "63",
      label: "TOHOUÈ",
      value: "443",
    },
    {
      COMMUNES: "64",
      label: "ADJA-OUÈRÈ",
      value: "444",
    },
    {
      COMMUNES: "64",
      label: "IKPINLÈ",
      value: "445",
    },
    {
      COMMUNES: "64",
      label: "KPOULOU",
      value: "446",
    },
    {
      COMMUNES: "64",
      label: "MASSÈ",
      value: "447",
    },
    {
      COMMUNES: "64",
      label: "OKO-AKARÈ",
      value: "448",
    },
    {
      COMMUNES: "64",
      label: "TOTONNOUKON",
      value: "449",
    },
    {
      COMMUNES: "65",
      label: "BANIGBÉ",
      value: "450",
    },
    {
      COMMUNES: "65",
      label: "DAAGBÉ",
      value: "451",
    },
    {
      COMMUNES: "65",
      label: "IFANGNI",
      value: "452",
    },
    {
      COMMUNES: "65",
      label: "KO-KOUMOLOU",
      value: "453",
    },
    {
      COMMUNES: "65",
      label: "LAGBÉ",
      value: "454",
    },
    {
      COMMUNES: "65",
      label: "TCHAADA",
      value: "455",
    },
    {
      COMMUNES: "66",
      label: "ADAKPLAMÉ",
      value: "456",
    },
    {
      COMMUNES: "66",
      label: "IDIGNY",
      value: "457",
    },
    {
      COMMUNES: "66",
      label: "KPANKOU",
      value: "458",
    },
    {
      COMMUNES: "66",
      label: "KÉTOU",
      value: "459",
    },
    {
      COMMUNES: "66",
      label: "ODOMETA",
      value: "460",
    },
    {
      COMMUNES: "66",
      label: "OKPOMETA",
      value: "461",
    },
    {
      COMMUNES: "67",
      label: "AHOYÉYÉ",
      value: "462",
    },
    {
      COMMUNES: "67",
      label: "IGANA",
      value: "463",
    },
    {
      COMMUNES: "67",
      label: "ISSABA",
      value: "464",
    },
    {
      COMMUNES: "67",
      label: "POBÈ",
      value: "465",
    },
    {
      COMMUNES: "67",
      label: "TOWÉ",
      value: "466",
    },
    {
      COMMUNES: "68",
      label: "AGUIDI",
      value: "467",
    },
    {
      COMMUNES: "68",
      label: "ITA-DJÈBOU",
      value: "468",
    },
    {
      COMMUNES: "68",
      label: "SAKÉTÉ I",
      value: "469",
    },
    {
      COMMUNES: "68",
      label: "SAKÉTÉ II",
      value: "470",
    },
    {
      COMMUNES: "68",
      label: "TAKON",
      value: "471",
    },
    {
      COMMUNES: "68",
      label: "YOKO",
      value: "472",
    },
    {
      COMMUNES: "69",
      label: "VIDOLE    ",
      value: "473",
    },
    {
      COMMUNES: "69",
      label: "DJEGBE",
      value: "474",
    },
    {
      COMMUNES: "69",
      label: "HOUNLI",
      value: "475",
    },
    {
      COMMUNES: "69",
      label: "ZOUNZONME",
      value: "476",
    },
    {
      COMMUNES: "69",
      label: "AGBOKPA",
      value: "477",
    },
    {
      COMMUNES: "69",
      label: "DETOHOU",
      value: "478",
    },
    {
      COMMUNES: "69",
      label: "SEHOUN",
      value: "479",
    },
    {
      COMMUNES: "70",
      label: "ADAHONDJIGON",
      value: "480",
    },
    {
      COMMUNES: "70",
      label: "ADINGNINGON",
      value: "481",
    },
    {
      COMMUNES: "70",
      label: "AGBANGNIZOUN",
      value: "482",
    },
    {
      COMMUNES: "70",
      label: "KINTA",
      value: "483",
    },
    {
      COMMUNES: "70",
      label: "KPOTA",
      value: "484",
    },
    {
      COMMUNES: "70",
      label: "LISSAZOUNMÈ",
      value: "485",
    },
    {
      COMMUNES: "70",
      label: "SAHÉ",
      value: "486",
    },
    {
      COMMUNES: "70",
      label: "SIWÉ",
      value: "487",
    },
    {
      COMMUNES: "70",
      label: "TANVÉ",
      value: "488",
    },
    {
      COMMUNES: "70",
      label: "ZOUNGOUDO",
      value: "489",
    },
    {
      COMMUNES: "71",
      label: "AGONGOINTO",
      value: "490",
    },
    {
      COMMUNES: "71",
      label: "AVOGBANNA",
      value: "491",
    },
    {
      COMMUNES: "71",
      label: "BOHICON I",
      value: "492",
    },
    {
      COMMUNES: "71",
      label: "BOHICON II",
      value: "493",
    },
    {
      COMMUNES: "71",
      label: "GNIDJAZOUN",
      value: "494",
    },
    {
      COMMUNES: "71",
      label: "LISSÈZOUN",
      value: "495",
    },
    {
      COMMUNES: "71",
      label: "OUASSAHO",
      value: "496",
    },
    {
      COMMUNES: "71",
      label: "PASSAGON",
      value: "497",
    },
    {
      COMMUNES: "71",
      label: "SACLO",
      value: "498",
    },
    {
      COMMUNES: "71",
      label: "SODOHOMÈ",
      value: "499",
    },
    {
      COMMUNES: "72",
      label: "ADOGBÉ",
      value: "500",
    },
    {
      COMMUNES: "72",
      label: "GOUNLI",
      value: "501",
    },
    {
      COMMUNES: "72",
      label: "HOUÉKO",
      value: "502",
    },
    {
      COMMUNES: "72",
      label: "HOUEN-HOUNSO",
      value: "503",
    },
    {
      COMMUNES: "72",
      label: "LAINTA-COGBÈ",
      value: "504",
    },
    {
      COMMUNES: "72",
      label: "NAOGON",
      value: "505",
    },
    {
      COMMUNES: "72",
      label: "SOLI",
      value: "506",
    },
    {
      COMMUNES: "72",
      label: "ZOGBA",
      value: "507",
    },
    {
      COMMUNES: "73",
      label: "AGONDJI",
      value: "508",
    },
    {
      COMMUNES: "73",
      label: "AGOUNA",
      value: "509",
    },
    {
      COMMUNES: "73",
      label: "DAN",
      value: "510",
    },
    {
      COMMUNES: "73",
      label: "DJIDJA",
      value: "511",
    },
    {
      COMMUNES: "73",
      label: "DOHOUIMÈ",
      value: "512",
    },
    {
      COMMUNES: "73",
      label: "GOBAIX",
      value: "513",
    },
    {
      COMMUNES: "73",
      label: "MONSOUROU",
      value: "514",
    },
    {
      COMMUNES: "73",
      label: "MOUGNON",
      value: "515",
    },
    {
      COMMUNES: "73",
      label: "OUNGBÈGAMÈ",
      value: "516",
    },
    {
      COMMUNES: "73",
      label: "OUTO",
      value: "517",
    },
    {
      COMMUNES: "73",
      label: "SETTO",
      value: "518",
    },
    {
      COMMUNES: "73",
      label: "ZOUKON",
      value: "519",
    },
    {
      COMMUNES: "74",
      label: "DASSO",
      value: "520",
    },
    {
      COMMUNES: "74",
      label: "OUINHI",
      value: "521",
    },
    {
      COMMUNES: "74",
      label: "SAGON",
      value: "522",
    },
    {
      COMMUNES: "74",
      label: "TOHOUÉ",
      value: "523",
    },
    {
      COMMUNES: "75",
      label: "AGONLI-HOUÉGBO",
      value: "524",
    },
    {
      COMMUNES: "75",
      label: "BANAMÈ",
      value: "525",
    },
    {
      COMMUNES: "75",
      label: "N'-TAN",
      value: "526",
    },
    {
      COMMUNES: "75",
      label: "DOVI",
      value: "527",
    },
    {
      COMMUNES: "75",
      label: "KPÉDÉKPO",
      value: "528",
    },
    {
      COMMUNES: "75",
      label: "ZAGNANADO",
      value: "529",
    },
    {
      COMMUNES: "76",
      label: "ALLAHÉ",
      value: "530",
    },
    {
      COMMUNES: "76",
      label: "ASSALIN",
      value: "531",
    },
    {
      COMMUNES: "76",
      label: "HOUNGOMEY",
      value: "532",
    },
    {
      COMMUNES: "76",
      label: "KPAKPAMÈ",
      value: "533",
    },
    {
      COMMUNES: "76",
      label: "KPOZOUN",
      value: "534",
    },
    {
      COMMUNES: "76",
      label: "ZA-KPOTA",
      value: "535",
    },
    {
      COMMUNES: "76",
      label: "ZA-TANTA",
      value: "536",
    },
    {
      COMMUNES: "76",
      label: "ZÈKO",
      value: "537",
    },
    {
      COMMUNES: "77",
      label: "AKIZA",
      value: "538",
    },
    {
      COMMUNES: "77",
      label: "AVLAMÈ",
      value: "539",
    },
    {
      COMMUNES: "77",
      label: "CANA I",
      value: "540",
    },
    {
      COMMUNES: "77",
      label: "CANA II",
      value: "541",
    },
    {
      COMMUNES: "77",
      label: "DOMÈ",
      value: "542",
    },
    {
      COMMUNES: "77",
      label: "KOUSSOUKPA",
      value: "543",
    },
    {
      COMMUNES: "77",
      label: "KPOKISSA",
      value: "544",
    },
    {
      COMMUNES: "77",
      label: "MASSI",
      value: "545",
    },
    {
      COMMUNES: "77",
      label: "TANWÉ-HESSOU",
      value: "546",
    },
    {
      COMMUNES: "77",
      label: "ZOGBODOMEY",
      value: "547",
    },
    {
      COMMUNES: "77",
      label: "ZOUKOU",
      value: "548",
    },
  ],
  fakepost = {
    nbreJourPresence: 0,
    validerFacilitateur: false,
    nbreFilleFreqClub: 0,
    validerRseN2: false,
    nbreFemmeFilleFormer: 0,
    observationRseN2: [""],
    nbreHommeGarconFormer: 5,
    validerRseN1: false,
    genreClub: "",
    observationRseN1: [""],
    nbreGarconFreqClub: 0,
    nomMentor: "",
    validerAdmin: false,
    observationAdmin: [""],
    quartier: {
      libelle: "",
      arrondissement: "",
    },
    collecteur: "",
    facilitateur: "",
    beneficiaire: {
      numOrdre: "",
      nom: "",
      prenom: "",
      sexe: "F",
      age: 0,
      statutEnfant: "",
      niveauDecrochageEnfant: "",
      logement: "",
      nonMarie: "",
      marie: "",
      numM2EducMaster: "",
      etablissementUniversite: "",
      niveauEtudeClasse: "",
      typeAppui: "",
      categorieFille: "",
      lieuNaissance: "",
      regulariteFilleCours: "",
      raisonAbsenceCoursFille: "",
      periodeCompoFille: "",
      decisionFinaleEtablissementFille: "",
      observationMoyenneFille: "",
      cibleDirecte: false,
      handicape: false,
      contact: "",
      connaissanceAppui: false,
      fratrieEnfant: false,
      typeFamille: "",
      utilisationAppui: "",
      completudeAppui: false,
      encadrementMaison: false,
      implicationParentale: "",
      appreciationEnvironnementScolaire: "",
      groupeFille: "",
      urlPhotoFille: "",
      frequenceCours: "",
      aRecuKit: false,
      nbreKitRecu: 0,
      nomMentor: "",
      prenomMentor: "",
      quartier: {
        libelle: "",
        arrondissement: "",
      },
      facilitateur: "",
      critereVulnerabilite: [""],
    },
    categorieFiche: "",
  },
  usertypes = [
    { label: "Fiche", value: "fiche" },
    { label: "Admin", value: "admin" },
    { label: "Agent ANTS", value: "agent_ants" },
    { label: "Donneur", value: "donneur" },
    { label: "ONG", value: "ong" },
  ],
  userroles = [
    { label: "Fiche", value: "ROLE_FICHE" },
    { label: "Admin", value: "ROLE_ADMIN" },
    { label: "Agent ANTS", value: "ROLE_AGENT_ANTS" },
    { label: "Donneur", value: "ROLE_DONNEUR" },
    { label: "Technicien", value: "ROLE_TECHNICIEN" },
    { label: "Encodeur", value: "ROLE_ENCODEUR" },
    { label: "ONG", value: "ROLE_ONG" },
  ],
  extraroles = [
    { label: "Admin", value: "ROLE_ADMIN", descriptions: "tout gérer" },
    {
      label: "Encodeur",
      value: "ROLE_ENCODEUR",
      descriptions: "Gérer let prelevements et resultats ",
    },
    {
      label: "Communicateur",
      value: "ROLE_MESSAGE",
      descriptions: "Gérer tous les Chats et discussions",
    },
    {
      label: "Gestionnaire",
      value: "ROLE_GESTIONNAIRE",
      descriptions: "Gérer les rdv,campagne et participations aux évènements",
    },
    {
      label: "Moniteur",
      value: "ROLE_MONITEUR",
      descriptions: "Voir les statistics",
    },
    {
      label: "News Manager",
      value: "ROLE_JOURNALISTE",
      descriptions: "Gérer les Actualités",
    },
    {
      label: "Donneur Manager",
      value: "ROLE_GEST_USER",
      descriptions: "Gérer les donneurs et patenaire",
    },
    {
      label: "Opérateur Manager",
      value: "ROLE_GEST_AGENT",
      descriptions: "Gérer les opérateurs",
    },
    {
      label: "Centre Manager",
      value: "ROLE_TERRIEN",
      descriptions: "Gérer les communes, les département et les centres.",
    },
    {
      label: "Quiz Manager",
      value: "ROLE_PROFESSEUR",
      descriptions: "Gérer les Quiz et les Faq.",
    },
    {
      label: "Fonction Manager",
      value: "ROLE_TECHNO_STRUCTURE",
      descriptions:
        "Gérer les professions, les groupes sanguins et les alertes",
    },
    { label: "Agent ANTS", value: "ROLE_AGENT_ANTS" },
  ],
  notparticipreasons = [
    { label: "Refus", value: "refused" },
    { label: "Eliminé pour raison sanitaire", value: "not_satitized" },
    { label: "Conditions défavorables", value: "unfavorable" },
    { label: "Incomplet", value: "not_completed" },
    { label: "Autre", value: "other" },
  ],
  activities = [
    { label: "Activé", value: true },
    { label: "Désactivé", value: false },
  ],
  publicationsPublitions = [
    { label: "Publiée", value: true },
    { label: "Cachée", value: false },
  ],
  examsvalues = [
    { label: "", value: "" },
    { label: "+", value: "+" },
    { label: "-", value: "-" },
  ],
  hbvalues = [
    { label: "AA", value: "" },
    { label: "AS", value: "AS" },
    { label: "SS", value: "SS" },
    { label: "AC", value: "AC" },
    { label: "SC", value: "SC" },
    { label: "CC", value: "CC" },
  ],
  eventType = [
    { label: "En attente de validation", value: "pending", color: "grey" },
    { label: "Annulé", value: "canceled", color: "red" },
    { label: "Validé", value: "validated", color: "yellow" },
    { label: "Publié", value: "published", color: "green" },
  ],
  campaignType = [
    { label: "En attente de validation", value: "pending", color: "grey" },
    { label: "Annulée", value: "canceled", color: "red" },
    { label: "Validée", value: "validated", color: "yellow" },
    { label: "Publiée", value: "published", color: "green" },
  ],
  component = () => ({
    TR: "Total de refus",
    TE: "Total effectif",
    NPO: "Nombre de poches",
    NPA: "Nombre de personnes attendues",
    P: "Partenaire",
    INS: "Nombre d'inscrits",
    PAR: "Nombre de participants",
    REF: "Nombre de refus",
  }),
  eventStatus = {
    pending: "En attente de validation",
    canceled: "Annulé",
  },
  actions = [
    {
      "@id": "/api/actions/301fe6f2-7f93-4318-b734-d094ae4a531c",
      "@type": "Actions",
      id: "301fe6f2-7f93-4318-b734-d094ae4a531c",
      name: "Créer",
      slug: "CREATE",
    },
    {
      "@id": "/api/actions/d3223a63-fe02-4010-ad9b-2107dc195e3e",
      "@type": "Actions",
      id: "d3223a63-fe02-4010-ad9b-2107dc195e3e",
      name: "Consulter",
      slug: "READ",
    },
    {
      "@id": "/api/actions/94d8a302-ac50-4398-89ca-98a8aa90ac38",
      "@type": "Actions",
      id: "94d8a302-ac50-4398-89ca-98a8aa90ac38",
      name: "Modifier",
      slug: "UPDATE",
    },
    {
      "@id": "/api/actions/f59df5a6-c120-48b8-b1e0-42e0354dcfc4",
      "@type": "Actions",
      id: "f59df5a6-c120-48b8-b1e0-42e0354dcfc4",
      name: "Supprimer",
      slug: "DELETE",
    },
    {
      "@id": "/api/actions/05bf7274-a251-4e0f-857d-b28f2a9070df",
      "@type": "Actions",
      id: "05bf7274-a251-4e0f-857d-b28f2a9070df",
      name: "Confirmer",
      slug: "CONFIRM",
    },

    {
      "@id": "/api/actions/33b17932-bb92-416f-b285-74f631f0eaaa",
      "@type": "Actions",
      id: "33b17932-bb92-416f-b285-74f631f0eaaa",
      name: "Désactiver",
      slug: "DESACTIVATE",
    },
    {
      "@id": "/api/actions/49aa9a6b-2d37-4c55-b75e-3f5b754785cc",
      "@type": "Actions",
      id: "49aa9a6b-2d37-4c55-b75e-3f5b754785cc",
      name: "Dépublier",
      slug: "UNPUBLISH",
    },
    {
      "@id": "/api/actions/90b6975c-b8a3-43e3-b55b-e31ad82f1bfa",
      "@type": "Actions",
      id: "90b6975c-b8a3-43e3-b55b-e31ad82f1bfa",
      name: "Publier",
      slug: "PUBLISH",
    },

    {
      "@id": "/api/actions/b2e7464d-a9a4-488e-972b-3855d02a7ee9",
      "@type": "Actions",
      id: "b2e7464d-a9a4-488e-972b-3855d02a7ee9",
      name: "Valider",
      slug: "VALIDATE",
    },

    {
      "@id": "/api/actions/e8c4da97-149a-4c4b-a7ed-c29aaefd199f",
      "@type": "Actions",
      id: "e8c4da97-149a-4c4b-a7ed-c29aaefd199f",
      name: "Annuler",
      slug: "CANCEL",
    },

    {
      "@id": "/api/actions/fa1b0a39-564a-4277-b9af-d5f6732ba87c",
      "@type": "Actions",
      id: "fa1b0a39-564a-4277-b9af-d5f6732ba87c",
      name: "Activer",
      slug: "ACTIVATE",
    },
  ].map((m) => m.slug),
  ressources = [
    {
      "@id": "/api/ressources/330240f6-9a77-4f91-ba90-cefeaaef3097",
      "@type": "Ressources",
      id: "330240f6-9a77-4f91-ba90-cefeaaef3097",
      name: "Action Utilisateur",
      slug: "Actions",
    },
    {
      "@id": "/api/ressources/2a58a926-50c9-4d85-919c-669a2aeb2e90",
      "@type": "Ressources",
      id: "2a58a926-50c9-4d85-919c-669a2aeb2e90",
      name: "Alerte par notification push",
      slug: "MessageFcm",
    },
    {
      "@id": "/api/ressources/c1f30d93-6b68-438c-8096-47ad7fde7895",
      "@type": "Ressources",
      id: "c1f30d93-6b68-438c-8096-47ad7fde7895",
      name: "Campagne dons de sang",
      slug: "Event",
    },
    {
      "@id": "/api/ressources/dc809a06-2d5a-4ba7-8b1d-f02b1f4920dd",
      "@type": "Ressources",
      id: "dc809a06-2d5a-4ba7-8b1d-f02b1f4920dd",
      name: "Categorie Faq",
      slug: "CategoryFaq",
    },
    {
      "@id": "/api/ressources/a0cb0c1b-7acd-4158-bc07-0aae9e7370db",
      "@type": "Ressources",
      id: "a0cb0c1b-7acd-4158-bc07-0aae9e7370db",
      name: "Categorie publication",
      slug: "CategoryPublication",
    },
    {
      "@id": "/api/ressources/cce2a828-22fb-4b8f-a454-7ee66983bbe1",
      "@type": "Ressources",
      id: "cce2a828-22fb-4b8f-a454-7ee66983bbe1",
      name: "Centre de prélèvements",
      slug: "Centre",
    },
    {
      "@id": "/api/ressources/b7d9d1d0-35ff-4ddd-bbf3-a48532c3e327",
      "@type": "Ressources",
      id: "b7d9d1d0-35ff-4ddd-bbf3-a48532c3e327",
      name: "Client Pour notification Push Mobile",
      slug: "ClientFcm",
    },
    {
      "@id": "/api/ressources/beddcf2c-29f5-4693-bdcb-ee3ecfc544a4",
      "@type": "Ressources",
      id: "beddcf2c-29f5-4693-bdcb-ee3ecfc544a4",
      name: "Conversation entre centre et utilisateurs",
      slug: "Conversation",
    },
    {
      "@id": "/api/ressources/1837fbca-a501-4c1f-bfc2-612df2be928c",
      "@type": "Ressources",
      id: "1837fbca-a501-4c1f-bfc2-612df2be928c",
      name: "Destinataire d'une notification",
      slug: "Destinataire",
    },
    {
      "@id": "/api/ressources/a747f952-861a-4ffb-a614-a8dbf69a547f",
      "@type": "Ressources",
      id: "a747f952-861a-4ffb-a614-a8dbf69a547f",
      name: "Droits Utilisateurs",
      slug: "UserAcl",
    },
    {
      "@id": "/api/ressources/3274af91-2076-41e9-bb39-be90e50186c1",
      "@type": "Ressources",
      id: "3274af91-2076-41e9-bb39-be90e50186c1",
      name: "Examens",
      slug: "Examens",
    },
    {
      "@id": "/api/ressources/fdfa6afb-992c-49b9-a3ee-66ecd2372b29",
      "@type": "Ressources",
      id: "fdfa6afb-992c-49b9-a3ee-66ecd2372b29",
      name: "Faq",
      slug: "Faq",
    },
    {
      "@id": "/api/ressources/3f872c39-345c-476c-82b0-fe029420448b",
      "@type": "Ressources",
      id: "3f872c39-345c-476c-82b0-fe029420448b",
      name: "Groupe Sanguins",
      slug: "GroupeRare",
    },
    {
      "@id": "/api/ressources/cbeff7dd-7293-4e02-872b-47dc401e885a",
      "@type": "Ressources",
      id: "cbeff7dd-7293-4e02-872b-47dc401e885a",
      name: "Inscription potentiels Donneurs ",
      slug: "FicheInscription",
    },
    {
      "@id": "/api/ressources/9f652b1f-5409-4304-b70d-3df88b83d201",
      "@type": "Ressources",
      id: "9f652b1f-5409-4304-b70d-3df88b83d201",
      name: "Localité Département",
      slug: "Departement",
    },
    {
      "@id": "/api/ressources/729b2468-e487-40f9-90db-25688f394196",
      "@type": "Ressources",
      id: "729b2468-e487-40f9-90db-25688f394196",
      name: "Localités communes",
      slug: "Localite",
    },
    {
      "@id": "/api/ressources/fd485b05-aa3d-43ee-acd8-0659f4ebc430",
      "@type": "Ressources",
      id: "fd485b05-aa3d-43ee-acd8-0659f4ebc430",
      name: "Médias",
      slug: "MediaObject",
    },
    {
      "@id": "/api/ressources/1714cb78-54c5-4155-9a8a-ff9b702e639d",
      "@type": "Ressources",
      id: "1714cb78-54c5-4155-9a8a-ff9b702e639d",
      name: "Message entre centre et utilisateur",
      slug: "Message",
    },
    {
      "@id": "/api/ressources/1b09b8ac-3715-4506-8031-e4c1069baed0",
      "@type": "Ressources",
      id: "1b09b8ac-3715-4506-8031-e4c1069baed0",
      name: "Notification ",
      slug: "Notification",
    },
    {
      "@id": "/api/ressources/a6e86395-a1f8-4b0e-aeb4-0f6fbce1b095",
      "@type": "Ressources",
      id: "a6e86395-a1f8-4b0e-aeb4-0f6fbce1b095",
      name: "Occupations",
      slug: "Fonction",
    },
    {
      "@id": "/api/ressources/4e98701c-c4a4-485b-bd3b-4f762e0c6b1f",
      "@type": "Ressources",
      id: "4e98701c-c4a4-485b-bd3b-4f762e0c6b1f",
      name: "Parametres application",
      slug: "Parametre",
    },
    {
      "@id": "/api/ressources/fc702965-fec2-4b68-84fb-0375c002f22b",
      "@type": "Ressources",
      id: "fc702965-fec2-4b68-84fb-0375c002f22b",
      name: "Partenaires",
      slug: "Ong",
    },
    {
      "@id": "/api/ressources/5b919be8-44fb-4802-a71c-eac3ee05dc01",
      "@type": "Ressources",
      id: "5b919be8-44fb-4802-a71c-eac3ee05dc01",
      name: "Participants conversations",
      slug: "ParticipantConversation",
    },
    {
      "@id": "/api/ressources/3bcfba0d-7982-4824-af4c-38e6df0379ee",
      "@type": "Ressources",
      id: "3bcfba0d-7982-4824-af4c-38e6df0379ee",
      name: "Participations aux campagnes",
      slug: "ParticipationEvent",
    },
    {
      "@id": "/api/ressources/6673ae92-7ae2-4559-b214-cbac4909f29a",
      "@type": "Ressources",
      id: "6673ae92-7ae2-4559-b214-cbac4909f29a",
      name: "Prélèvements",
      slug: "Prelevements",
    },
    {
      "@id": "/api/ressources/458092c7-272b-48cc-89a2-06701132efc7",
      "@type": "Ressources",
      id: "458092c7-272b-48cc-89a2-06701132efc7",
      name: "Publication",
      slug: "Publication",
    },
    {
      "@id": "/api/ressources/2ab0c5c5-5f04-4c3e-80cd-4242aab854c9",
      "@type": "Ressources",
      id: "2ab0c5c5-5f04-4c3e-80cd-4242aab854c9",
      name: "Quiz",
      slug: "Quiz",
    },
    {
      "@id": "/api/ressources/541a0b31-2daa-4d70-99af-2498ad73e687",
      "@type": "Ressources",
      id: "541a0b31-2daa-4d70-99af-2498ad73e687",
      name: "Rendez-vous",
      slug: "PriseRdv",
    },
    {
      "@id": "/api/ressources/03e7f2f3-7d93-471b-b43c-3151ce8a772e",
      "@type": "Ressources",
      id: "03e7f2f3-7d93-471b-b43c-3151ce8a772e",
      name: "Ressources",
      slug: "Ressources",
    },
    {
      "@id": "/api/ressources/27a71967-1704-4e68-8383-b97933d8b78f",
      "@type": "Ressources",
      id: "27a71967-1704-4e68-8383-b97933d8b78f",
      name: "Résulats des examens",
      slug: "ResultatExamens",
    },
  ].map((m) => m.slug);
