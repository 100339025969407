const Globals = {
  messagePerPage: 10,
  itemsPerPage: 10,
  userPerPage: 10,
  worker: {
    userUpdateConfirmTitle: {
      isActivated: "Activation de compte",
      Niveau: "Vérification",
      type: "Changement de type",
      removerole: "Rôle utilisateur",
    },
    eventStatLabels: {
      femmes: "Femmes",
      hommes: "Hommes",
      nbrConfirmations: "Nombre confirmé",
      nbrParticpations: "Nombre de participation",
      nbrRefus: "Nombre de refus",
    },
    userUpdateConfirmMessage: {
      isActivated:
        "Voulez-vous vraiment changer le status pour cet utilisateur",
      Niveau: "Voulez-vous vraiment changer le status pour cet utilisateur",
      type: "Voulez-vous vraiment changer de type pour cet utilisateur",
      removerole: "Voulez-vous vraiment retirer ce rôle pour cet utilisateur ?",
    },
    fetchedAgentsTolinks: [],
    selectedcourseID: 0,
    selectedLessonID: 0,
    selectedSlide: {},
    selectedcourse: "",
    setcitadel: () => {},
    UpdateSlide: () => {},
    reponarr: [],
    history: {},
    sale: {},
  },
  FONTS: {},
  COLORS: {
    primary: "#b91108",
    secondary: "#204b9bff",
    accent: "#50c878",
  },
  COOKIE_DURATIONN: {
    TOKEN_AUTHENTICATE: 5184000000,
  },
  STRINGS: {
    SearchesDevis: "Avis de recherche",
    Mylessons: "Mes Leçons",
    Ocurred_error:
      "Une erreur est suvenue! Vérifiez votre connexion et reéssayez .",
    small_pass: "Mot de passe trop court (6 lettres minimum)",
    small_username: "nom d'utilisateur trop court (6 lettres minimum) ",
    long_username: "nom d'utilisateur trop long (50 lettres maximun)",
    recquired_username: "nom d'utilisateur requis",
    small_firstname: "Nom trop court (6 lettres minimum) ",
    small_lastname: "Prénom trop court (6 lettres minimum) ",
    long_firstname: "Nom trop long (50 lettres maximun)",
    long_lastname: "Prénom trop long (50 lettres maximun)",
    not_url: "addresse url invalide",
    not_mail: "addresse e-mail invalide",
    recquired_pass: "mot de passe requis",
    incorect_user_or_pass: "nom d'utilisateur ou mot de passe incorrect !",
    already_taken_username:
      "nom d'utilisateur indisponible. choisissez un autre ",
    unexisted_user: "utilisateur introuvable .",
    already_taken_mail: "addresse e-mail indisponible. choisissez un autre ",
    succesfully_signup: "Inscription reuissi . redirection à la page d'accueil",
    succesfully_signin: "Connexion reuissi . redirection à la page d'accueil",
    succesfully_signout:
      "Déconnexion reuissie . redirection à la page d'accueil",
    constrain_title: `titre du cour 200 caractères maximum `,
    short_description: `une courte description du cour, soyez bref `,
    course_description: `description du cour `,
    constrain_video: `type mp4 ( < 6Mo) `,
    constrain_video_section: `type mp4 ( < 100Mo) `,
    constrain_cover_course: `fichier type png,jpg de format 400x500 px ( <= 5Mo)`,
    constrain_cover_profil: `fichier type png,jpg de format 604x604 px ( <= 5Mo)`,
    sucess_Update: `Mise à jour reuissi`,
    sucess_Notification_sended: `Notification Partagée`,
    sucess_Message_sended: `Message Envoyé`,
    constrain_url: `lien http`,
    constrain_string_size: (li, si) =>
      `Doit respecter la contrainte de taille des caractères : [${li} ${si}]`,
  },
};
export default Globals;
