import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
  USER_INFOS,
  AGENT_SECRET,
  ADMIN_SECRET,
  USER_DATA,
  AUTH_TOKEN_REFRESH,
  INVITE_SECRET,
} from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
} from "../actions/Auth";
import history from "../../history";

import FirebaseService from "services/FirebaseService";
import FetcherService from "services/FetcherService";
import jwt from "jsonwebtoken";
import Globals from "constants/Globals";
import { extraroles } from "constants/properties.js";
import { uniquize } from "helpers/utils";

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      var user = {};
      const searchParams = new URLSearchParams(window.location.search);
      if(searchParams.has('token')) {
        user.token = searchParams.get('token');
        user.refresh_token = '';
      }else{
        user = yield call(FetcherService.AuthSignin, {
          username: email,
          password,
        });
      }
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        var decoded = jwt.decode(user.token);
        const roles = decoded.roles;
        const isAgent = roles?.includes("ROLE_AGENT_ANTS");
        const isInvite = `${decoded.type}`.includes("invite");
        const isAdmin = `${decoded.type}`.includes("admin");
        const extra_roles = extraroles.map((mes) => mes.value);
        let canLogin = false;
        for (let pi = 0; pi < extra_roles.length; pi++) {
          if (roles?.includes(extra_roles[pi])) {
            canLogin = true;
          }
        }
        if (canLogin || isAdmin || isAgent || isInvite) {
          let userData = yield call(FetcherService.GetUserData, {
            page: 1,
            itemsPerPage: 30,
            username: decoded.username,
          });

          userData = userData["hydra:member"][0];
          
          if(!userData) {
            userData = yield call(FetcherService.GetEdsUserData, {
              page: 1,
              itemsPerPage: 30,
              username: decoded.username,
            });
            userData = userData["hydra:member"][0];
          }

          if (!userData.isActivated) {
            yield put(
              showAuthMessage("Votre compte est temporairement bloqué.")
            );
            return;
          }

          let userToles = userData.roles,
            requests = [];

          for (let ki = 0; ki < userToles.length; ki++) {
            requests.push(
              FetcherService.GetRoles({
                name: userToles[ki],
              })
            );
          }
          const res = yield Promise.all(requests);

          if (
            res[0] &&
            (res[0]["@type"] !== undefined || typeof res[0] === "string")
          ) {
            let usersList = [];
            for (let di = 0; di < res.length; di++) {
              usersList.push(...res[di]["hydra:member"]);
            }
            usersList = uniquize(usersList, "id");
            let acllist = [];
            for (let ai = 0; ai < usersList.length; ai++) {
              acllist.push(...usersList[ai].roleAcls);
            }
            //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
            acllist =
              typeof acllist[0] === "string"
                ? yield Promise.all(
                    acllist.map(async (mes) => {
                      const event = await FetcherService.GetRoleAcl(
                        {},
                        `${mes}`.substring(1 + `${mes}`.lastIndexOf("/"))
                      );
                      if (event?.id) {
                        mes = event;
                      }
                      return { ...mes };
                    })
                  )
                : acllist;
            //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

            userData.roleAcls = acllist;
          }
          let routeToRedirect = "";
          if (isAdmin) {
            localStorage.setItem(ADMIN_SECRET, "bene");
            routeToRedirect = "/app/dashboards/home";
          }
          if (isAgent) {
            localStorage.setItem(AGENT_SECRET, "bene");
            routeToRedirect = "/app/dashboards/home";
          }
          if (isInvite) {
            localStorage.setItem(INVITE_SECRET, "bene");
            routeToRedirect = "/app/dashboards/home";
          }

          localStorage.setItem(USER_INFOS, JSON.stringify(decoded));
          localStorage.setItem(USER_DATA, JSON.stringify(userData));
          localStorage.setItem(AUTH_TOKEN, user.token);
          localStorage.setItem(AUTH_TOKEN_REFRESH, user.refresh_token);

          yield put(authenticated(user.token));
          if (userData.isResetPassword === false) {
            routeToRedirect = "/app/settings/profile/change-password";
          }
          document.location.href = routeToRedirect;
        } else {
          yield put(
            showAuthMessage(
              "Nulle n'entre ici s'il n'est administrateur ou Agent de l'ANTS."
            )
          );
        }
      }
    } catch (err) {
      console.log(err);
      yield put(showAuthMessage(Globals.STRINGS.Ocurred_error));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      setTimeout(() => {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.clear();
        history.push("/auth/login");
        window.location.reload();
      }, 3000);

      const signOutUser = yield call(FetcherService.AuthSignout);

      if (signOutUser.signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.clear();
        yield put(signOutSuccess(signOutUser));
        document.location.href = "/auth/login";
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(Globals.STRINGS.Ocurred_error));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signUpEmailRequest,
        email,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(Globals.STRINGS.Ocurred_error));
    }
  });
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(FirebaseService.signInGoogleRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithGoogleAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(Globals.STRINGS.Ocurred_error));
    }
  });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(FirebaseService.signInFacebookRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithFacebookAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(Globals.STRINGS.Ocurred_error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
    fork(signUpWithFBEmail),
    fork(signInWithFBGoogle),
    fork(signInWithFacebook),
  ]);
}
