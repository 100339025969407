import React from "react";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
} from "constants/ThemeConstant";
import { APP_NAME } from "configs/AppConfig";
import { connect } from "react-redux";
import utils from "utils/utils";
import { Grid, Tooltip } from "antd";
import { getMediaUrl } from "helpers/utils";

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed } = props;

  if (isMobile && !props.mobileLogo) {
    return 0;
  }

  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  } else {
    return "logo";
  }
};

export const Logo = (props) => {
  let { appLogoSite, appName, appDescription } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <img src={getMediaUrl(appLogoSite)} alt={`logo`} width={50} height={50} />
      <span className="d-flex flex-column">
        <Tooltip title={appDescription}>
          <span
            style={{ lineHeight: "15px", fontSize: "22px", marginTop: "18px" }}
            className="font-weight-semibold ml-2"
          >
            {`${appName || APP_NAME}`}
          </span>
        </Tooltip>
        <span style={{ lineHeight: "25px", marginTop: "8px" }} className="ml-2">
          ANTS
        </span>
      </span>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
