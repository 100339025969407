import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT } from "constants/ThemeConstant";
import utils from "utils/utils";
import { onMobileNavToggle } from "redux/actions/Theme";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu, i) => {
        if (typeof menu.permission === "boolean" && !menu.permission) {
          return null;
        }
        return menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) => {
              if (
                typeof subMenuFirst.permission === "boolean" &&
                !subMenuFirst.permission
              ) {
                return null;
              }

              return subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      subMenuFirst.icontype === "ri" ? (
                        subMenuFirst?.icon
                      ) : (
                        <Icon type={subMenuFirst?.icon} />
                      )
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => {
                    if (
                      typeof subMenuSecond.permission === "boolean" &&
                      !subMenuSecond.permission
                    ) {
                      return null;
                    }
                    return (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          subMenuSecond.icontype === "ri" ? (
                            subMenuSecond?.icon
                          ) : (
                            <Icon type={subMenuSecond?.icon} />
                          )
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              ) : typeof subMenuFirst.permission === "boolean" &&
                !subMenuFirst.permission ? null : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    subMenuFirst.icontype === "ri" ? (
                      subMenuFirst?.icon
                    ) : (
                      <Icon type={subMenuFirst.icon} />
                    )
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuFirst.path}
                  />
                </Menu.Item>
              );
            })}
          </Menu.ItemGroup>
        ) : typeof menu.permission === "boolean" && !menu.permission ? null : (
          <Menu.Item key={menu.key}>
            {menu.icon ? (
              menu.icontype === "ri" ? (
                menu?.icon
              ) : (
                <Icon type={menu?.icon} />
              )
            ) : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const MenuContent = (props) => {
  return <SideNavContent {...props} />;
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
